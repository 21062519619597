/*
  Author : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 13/10/2023 17:59:30
*/
import React, { FC, useEffect } from 'react';
import './OnOff.css';


interface OnOffProps {
  on: boolean
}


const OnOff: FC<OnOffProps> = ({ on }) => {



  useEffect(() => {
    window.scrollTo(0, 0)
    const runLocalData = async () => {

    }
    runLocalData()
  })

  return (
    <div className="OnOff">
      {
        on ?

          <i className="fa-solid fa-toggle-on" />
          :
          <i className="fa-solid fa-toggle-off" />


      }
    </div>
  );
}

export default OnOff;