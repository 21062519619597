/*
  Author : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 16/10/2023 15:28:41
*/
import React, { FC, useEffect, useState } from 'react';
import './Watch.css';
import { Navigate, useLocation } from 'react-router-dom';
import VideoList from '../VideoList/VideoList';
import { useSelector } from 'react-redux';
import { getCurrentVideo } from '../../redux/selectors/selectors';
import { getVideoLink } from '../../api/api-entity';
import { OuitubePlayer } from 'ouitube-player';

interface WatchProps {

}


const Watch: FC<WatchProps> = () => {


  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentVideo = useSelector(getCurrentVideo)
  const videoCode = searchParams.get('v');
  
  const [redirect, setRedirect] = useState<boolean>(false)

  
  const [videoLink, setVideoLink] = useState<string>("")


  useEffect(() => {
  
    const runLocalData = async () => {
      if (!videoCode) {
        setRedirect(true)
      }else{
        const url = getVideoLink(videoCode)
        setVideoLink(url)
      }

    }
  
    runLocalData()
  }, [videoCode])
  

if (redirect) {
  return <Navigate to="/error" />
}

return (
  <div className="Watch">
    <div id="content-page" className="content-page">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-9">
            <div className="iq-card iq-card iq-card-block iq-card-stretch iq-card-height">
              <div className="iq-card-body pb-0">
                {
                  videoLink ?
                    <OuitubePlayer
                      src={videoLink}
                      onProgress={(e)=>console.log(e)}
                    />
                    :
                    null
                }
                <h3>
                  {currentVideo?.name}
                </h3>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <VideoList />
          </div>

        </div>

      </div>
    </div>
  </div>
);
}

export default Watch;