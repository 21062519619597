/*
  Author : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 10/10/2023 16:16:16
*/
import React, { FC, useEffect } from 'react';
import './ErrorPage.css';


interface ErrorPageProps {
 
}


const ErrorPage : FC<ErrorPageProps> = () =>{



    useEffect(() => {
      window.scrollTo(0,0)
      const runLocalData = async () => {

      }
      runLocalData()
    })

  return (
      <div className="ErrorPage">
        <div id="content-page" className="content-page">

          ErrorPage Component
        </div>
      </div>
  );
}

export default ErrorPage;