/*
  Author : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 23/10/2023 15:18:22
*/
import React, { FC, useEffect, useState } from 'react';
import './ChoosePlan.css';
import { Formule } from '../../models/Formule';
import { getDatas } from '../../api/api-entity';
import Loading from '../Loading/Loading';
import { formatPrice } from '../../helpers/utils';
import SubscriptionSwitch from '../SubscriptionSwitch/SubscriptionSwitch';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import { useTranslation } from 'react-i18next';
import { HeaderHome } from '../../pages/HeaderHome';


interface ChoosePlanProps {

}


const ChoosePlan: FC<ChoosePlanProps> = () => {

  const { t } = useTranslation();
  const [formules, setFormules] = useState<Formule[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isAnnually, setAnnually] = useState<boolean>(true)

  useEffect(() => {
    window.scrollTo(0, 0)
    const runLocalData = async () => {
      const newData: any = await getDatas('formule')
      if (newData.isSuccess) {
        setFormules(newData.results)
      }
      setIsLoading(false)
    }
    runLocalData()
  }, [isLoading])


  return (
    <div className='Home'>
      <HeaderHome />
      <div className="home-page">
        <div className="ChoosePlan container-lg mt-5">
          <h2 className="text-center mb-4">{t('choose_plan')} </h2>
          <SubscriptionSwitch
            onToggle={setAnnually}
          />
          <LanguageSelector
          />

          <div className="row">
            {
              formules?.length ?
                formules.map((formule, index) => {
                  return <div className="col-lg-3 formule-item col-md-6 p-1" key={index}>
                    <div className="card m-1 shadow border">
                      <div className="card-body">
                        <h2 className="card-title text-center">{formule?.name}</h2>
                        {
                          formule.monthly_price === 0 ?
                            <p className='d-flex justify-content-center'>Gratuit</p>
                            :
                            isAnnually ?
                              <div>
                                <p className='d-flex justify-content-center'>
                                  <span>{formatPrice(formule.annually_price)}</span>
                                  <del>
                                    <span>{formatPrice(formule.monthly_price)}</span>
                                  </del>
                                </p>
                                <p className='text-center'>
                                  {t('licence_annually')}
                                </p>


                              </div>
                              :
                              <div>
                                <p className='d-flex justify-content-center'>
                                  {formatPrice(formule.monthly_price)}
                                </p>
                                <p className='text-center text-sm'>
                                  {t('licence_monthly')}
                                </p>
                              </div>
                        }
                        <p>{t('storage')} : {formule.storage_size}</p>
                        <p>{t('limit_download')} : {formule.storage_limit}</p>
                        <div className='m-1 row'>
                          <button className="btn btn-primary col">{t('get_started')}</button>
                        </div>
                        <h6 className='mt-3'>Options</h6>
                        <ul className="mt-3">
                          {
                            formule.options.map((item: any) => {
                              return <li className="">{item.name}</li>
                            })
                          }
                        </ul>
                      </div>

                    </div>
                  </div>
                })
                :
                <Loading />
            }

          </div>
        </div>
      </div>
    </div>
  );
}

export default ChoosePlan;