/*
  Author : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 21/10/2023 17:25:57
*/
import React, { useEffect, useState } from 'react';
import { generateId } from '../../helpers/utils';
import { Origin } from '../../models/Origin';



interface AddOriginProps {
  handleChange: (value: Origin[]) => void
  options: Origin[]
}

const AddOrigin: React.FC<AddOriginProps> = ({options, handleChange}) => {

  const [origins, setOrigins] = useState<Origin[]>(options);
  const [newOrigin, setNewOrigin] = useState<string>('');
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editedOrigin, setEditedOrigin] = useState<string>('');

  useEffect(()=>{
    setOrigins(options)
  },[options.length])
  
  const handleOriginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewOrigin(e.target.value);
  };

  const addOrigin = () => {
    if (newOrigin) {
      const newId = generateId();
      const newOrigins = [...origins, { _id: newId, name: newOrigin, created_at: new Date() }]
      setOrigins(newOrigins);
      setNewOrigin('');
      handleChange(newOrigins)
    }
  };

  const startEdit = (index: number, origin: string) => {
    setEditingIndex(index);
    setEditedOrigin(origin);
  };

  const handleEditChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedOrigin(e.target.value);
  };

  const applyEdit = () => {
    if (editedOrigin) {
      const updatedOrigins = [...origins];
      updatedOrigins[editingIndex as number] = { ...updatedOrigins[editingIndex as number], name: editedOrigin, updated_at: new Date() };
      setOrigins(updatedOrigins);
      setEditingIndex(null);
      setEditedOrigin('');
      handleChange(updatedOrigins)
    }
  };

  const cancelEdit = () => {
    setEditingIndex(null);
    setEditedOrigin('');
  };

  const deleteOrigin = (index: number) => {
    const updatedOrigins = origins.filter((_, i) => i !== index);
    setOrigins(updatedOrigins);
    handleChange(updatedOrigins);
  };

  return (
    <div>
      <h5>Add Origin</h5>
      <div className="d-flex gap-1">
        <input
          type="text"
          placeholder="Nouvelle origine"
          className='form-control'
          value={newOrigin}
          onChange={handleOriginChange}
        />
        <button className='btn btn-primary ml-1' onClick={addOrigin}>Ajouter</button>
      </div>
      <ul>
        {origins.map((origin, index) => (
          <li key={origin._id}>
            {index === editingIndex ? (
              <div className='d-flex'>
                <input
                  type="text"
                  className='form-control'
                  value={editedOrigin}
                  onChange={handleEditChange}
                />
                <button  className='btn btn-primary ml-1' onClick={applyEdit}>Enregistrer</button>
                <button  className='btn btn-primary ml-1' onClick={cancelEdit}>Annuler</button>
              </div>
            ) : (
              <div className='mt-2 d-flex'>
                {origin.name}
                <div className="justify-content-center">
                  <button  className='btn btn-primary ml-1' onClick={() => startEdit(index, origin.name)}>Éditer</button>
                  <button  className='btn btn-primary ml-1' onClick={() => deleteOrigin(index)}>Supprimer</button>
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AddOrigin;
