/*
  Author : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 07/10/2023 20:53:44
*/
import React, { FC, useEffect, useState } from 'react';
import './SignIn.css';
import { Link, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getAuthState } from '../../redux/selectors/selectors';
import { useFormik } from 'formik';
import { authenticate } from '../../api/api-entity';
import { User } from '../../models/User';
import { ADD_NOTIFICATION } from '../../redux/actions/actionTypes';
import { validateSignInForm } from '../../validators/form-validator';
import { generateId } from '../../helpers/utils';
import { apiUrl } from '../../api/apiUtils';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import { getItem, removeItem } from '../../helpers/cookie.service';


interface SignInProps {

}


const SignIn: FC<SignInProps> = () => {

  const dispatch = useDispatch()
  const {t} = useTranslation()
  const isAuth = useSelector(getAuthState)
  const [redirect, setRedirect] = useState<any>(false)
  const [formError, setFormError] = useState<string>("");
  const validate = validateSignInForm
  

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validate,
    onSubmit: async (user: User) => {
      const result = await authenticate(user)
      // alert(JSON.stringify(result, null, 2));
      if (result.isSuccess) {
        setRedirect({
          userId: result.userId,
          partial_token: result.partial_token,
        })
        setFormError("")

      } else {
        setRedirect(false)
        setFormError(result.message)

        dispatch({
          type: ADD_NOTIFICATION,
          payload: {
            _id: generateId(),
            message: result.message || "Error, please try again !",
            status: "danger",
            timeout: 2000
          }
        })
      }
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0)
    const runLocalData = async () => {
      document.body.classList.remove("light")
    }
    runLocalData()
  },[])

  if (redirect) {
    return <Navigate to={"/verification/" + redirect?.partial_token} />
  }

  if (isAuth) {
    let pathname: any = getItem("pathname")
    if (pathname) {
      removeItem("pathname")
      return <Navigate to={pathname} />
    }
    return <Navigate to="/" />
  }

  return (
    <div className="SignIn">
      {/* <div id="loading">
        <div id="loading-center">
        </div>
      </div> */}
      {/* loader END */}
      {/* Sign in Start */}
      <section className="sign-in-page">
        <div className="container">
          <div className="row justify-content-center align-items-center height-self-center">
            <div className="col-lg-5 col-md-12 align-self-center form-padding">
              <div className="sign-user_card ">
                <div className="sign-in-page-data">
                  <div className="sign-in-from w-100 m-auto">
                    <h3 className="mb-3 text-center">{t('sign_in')}</h3>
                    <div className="d-flex justify-content-center links">
                      <Link to="/forgot-password" className="f-link">
                      {t('forgot_password')}
                        </Link>
                    </div>
                    <div className="error">{formError}</div>
                    <form noValidate className="mt-4" onSubmit={formik.handleSubmit} >
                      <div className="form-group">
                        <input
                          onChange={formik.handleChange}
                          type="email"
                          name='email'
                          className="form-control mb-0"
                          id="exampleInputEmail2"
                          placeholder={t('enter_email')}
                          autoComplete="off"
                          value={formik.values.email}
                          required />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="error">{formik.errors.email}</div>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <input
                          onChange={formik.handleChange}
                          value={formik.values.password}
                          type="password"
                          name="password"
                          className="form-control form-group mb-0"
                          id="exampleInputPassword2"
                          placeholder={t('enter_password')}
                          required />
                        {formik.touched.password && formik.errors.password ? (
                          <div className="error">{formik.errors.password}</div>
                        ) : null}
                      </div>
                      <div className="sign-info">
                        <button type="submit" className="btn btn-primary w-100">
                        {t('sign_in')}
                        </button>
                        {/* <div className="custom-control custom-checkbox d-inline-block">
                          <input type="checkbox" className="custom-control-input" id="customCheck" />
                          <label className="custom-control-label" htmlFor="customCheck">Remember Me</label>
                        </div> */}
                      </div>
                    </form>
                  </div>
                </div>
                <div className="mt-3">
                  <div className="d-flex justify-content-center links">
                  {t('dont_have_account')} 
                  <Link to="/signup" className="text-primary ml-2">{t('sign_up')}</Link>
                  </div>
                  <div className="sign-with-google mt-2">
                    <Link to={apiUrl+"oauth/auth/google"}>
                    <button className="btn btn-google w-100">
                      {t('login_with_google')}
                    </button>
                    </Link>

                    <LanguageSelector/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Sign in END */}
          {/* color-customizer */}
        </div>
      </section>

    </div>
  );
}

export default SignIn;