/*
  Author : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 07/10/2023 20:53:45
*/
import React, { FC, useEffect, useState } from 'react';
import './SignUp.css';
import { Link, Navigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { validateSignUpForm } from '../../validators/form-validator';
import {User} from '../../models/User';
import { useSelector } from 'react-redux';
import { getAuthState } from '../../redux/selectors/selectors';
import { signupAndAuthenticate } from '../../api/api-entity';
import { ADD_NOTIFICATION } from '../../redux/actions/actionTypes';
import { generateId } from '../../helpers/utils';
import { getItem, removeItem } from '../../helpers/localsorage.service';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import { apiUrl } from '../../api/apiUtils';


interface SignUpProps {

}


const SignUp: FC<SignUpProps> = () => {

  const dispatch = useDispatch()
  const validate = validateSignUpForm
  const [redirect, setRedirect] = useState<any>(false)
  const [formError, setFormError] = useState<string>("");
  const isAuth = useSelector(getAuthState)
  const {t} = useTranslation()
  
  const handleSubmit = async (user: User)=>{
    console.log(user);
    const result = await signupAndAuthenticate(user)
    // console.log(data);
    if (result.isSuccess) {
      setRedirect({
        userId: result.userId,
        partial_token: result.partial_token,
      })
      setFormError("")
    
    } else {
      setRedirect(false)
      setFormError(result.message)

      dispatch({
        type: ADD_NOTIFICATION,
        payload: {
          _id: generateId(),
          message: result.message || "Error, please try again !",
          status: "danger",
          timeout: 2000
        }
      })
    }
    
  }
  const formik = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    validate,
    onSubmit: (user: any) => {
      handleSubmit(user)
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0)
    const runLocalData = async () => {
      document.body.classList.remove("light")
    }
    runLocalData()
  },[])

  if (redirect) {
    return <Navigate to={"/verification/"+redirect?.partial_token} />
  }

  if (isAuth) {
    let pathname: any = getItem("pathname")
    if (pathname) {
      removeItem("pathname")
      return <Navigate to={pathname} />
    }
    return <Navigate to="/" />
  }

  return (
    <div className="SignUp">
      {/* Sign in Start */}
      <section className="sign-in-page">
        <div className="container">
          <div className="row justify-content-center align-items-center height-self-center">
            <div className=" align-self-center col-md-12 col-lg-7 form-padding">
              <div className="sign-user_card ">
                <div className="sign-in-page-data">
                  <div className="sign-in-from w-100 m-auto">
                    <form noValidate className="row" onSubmit={formik.handleSubmit}>
                      <div className="error">
                      {formError}
                      </div>
                      <div className="form-group col-md-12">
                        <label htmlFor="exampleInputEmail3">{t('email')}</label>
                        <input
                          type="email"
                          name='email'
                          className="form-control mb-0 btn-border"
                          id="email" 
                          placeholder={t('enter_email')}
                          autoComplete="off"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          required />
                          {formik.touched.email && formik.errors.email ? (
                            <div className='error'>{formik.errors.email}</div>
                          ) : null}
                      </div>
                      <div className="form-group col-md-12">
                        <label htmlFor="exampleInputEmail3">{t('fullName')}</label>
                        <input
                          onChange={formik.handleChange}
                          value={formik.values.fullName}
                          name='fullName'
                          type="fullName" 
                          className="form-control mb-0 btn-border" 
                          id="fullName" 
                          placeholder={t('enter_fullName')}
                          autoComplete="off" 
                          required />
                           {formik.touched.fullName && formik.errors.fullName ? (
                            <div className='error'>{formik.errors.fullName}</div>
                          ) : null}
                      </div>

                      <div className="form-group col-md-6">
                        <label htmlFor="exampleInputEmail6">{t('password')}</label>
                        <input
                        name='password'
                          onChange={formik.handleChange}
                          value={formik.values.password}
                          type="password" 
                          className="form-control mb-0 btn-border" 
                          id="password" 
                          placeholder={t('enter_password')}
                          autoComplete="off" required />
                             {formik.touched.password && formik.errors.password ? (
                            <div className='error'>{formik.errors.password}</div>
                          ) : null}
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="password">{t('repeat_password')}</label>
                        <input 
                        name='confirmPassword'
                        type="password" 
                        onChange={formik.handleChange}
                        value={formik.values.confirmPassword}
                        className="form-control mb-0 btn-border"
                        id="confirmPassword" 
                        placeholder={t('enter_confirm_password')}
                        required />
                           {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                            <div className='error'>{formik.errors.confirmPassword}</div>
                          ) : null}
                      </div>
                      <div className="form-group col-md-12">
                        <button type="submit" className="btn btn-primary my-2 w-100">{t('sign_up')}</button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="mt-3">
                  <div className="d-flex justify-content-center links">
                  {t('have_account')}
                    <Link to="/signin" className="text-primary ml-2">{t('sign_in')}</Link>
                  </div>
                </div>
                <div className="sign-with-google mt-2">
                    <Link to={apiUrl+"oauth/auth/google"}>
                    <button className="btn btn-google w-100">
                      {t('login_with_google')}
                    </button>
                    </Link>
                        
                    <LanguageSelector/>
                  </div>
              </div>
            </div>
          </div>
          {/* Sign in END */}
          {/* color-customizer */}
        </div>
      </section>
    </div>

  );
}

export default SignUp;