/*
  Author : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 12/10/2023 11:43:48
*/
import React, { FC, useEffect, useState } from 'react';
import './KeyInput.css';
import { ADD_NOTIFICATION } from '../../redux/actions/actionTypes';
import { generateId } from '../../helpers/utils';
import { useDispatch } from 'react-redux';
import copy from 'clipboard-copy';


interface KeyInputProps {
  keyValue: string
  keyName?: string
  inputType?: string
  disabled?: boolean
}


const KeyInput: FC<KeyInputProps> = ({ keyValue, keyName, inputType, disabled }) => {


  const dispatch = useDispatch()

  const [type, setType] = useState<string>(inputType || "password")

  useEffect(() => {
    
    const runLocalData = async () => {

    }
    runLocalData()
  })

  function copyToClipboard(textToCopy?: string, message?: string) {
    console.log({ textToCopy });

    if (textToCopy) {
      copy(textToCopy)
        .then(() => {
          console.log('Texte copié dans le presse-papiers :', textToCopy);
          dispatch({
            type: ADD_NOTIFICATION,
            payload: {
              _id: generateId(),
              message: "Copy " + message + " successfuly",
              status: "success",
              timeout: 2000
            }
          })
          // Vous pouvez également afficher un message de succès ici
        })
        .catch((error) => {
          console.error('Erreur lors de la copie dans le presse-papiers :', error);
          // Gérez les erreurs de copie ici
        });

    }
  }

  const handleSetType = () => {
    setType(type === "password" ? "text" : "password")
  }

  return (
    <div className="col-12 form-group p-0" >
      <label htmlFor="">{keyName}</label>
      <div className="d-flex gap-2 align-items-center">
        <input
          disabled={disabled === undefined ? true : disabled}
          type={type}
          value={keyValue}
          className="form-control mr-1"
          id={keyName}

        />
        {
          type === "password" ?
          <button className="m-2 btn btn-primary" onClick={handleSetType}>View</button>
          :
          <button className="m-2 btn btn-primary" onClick={handleSetType}>Hide</button>
        }
        <button className="m-2 btn btn-primary" onClick={() => copyToClipboard(keyValue, keyName)}>Copy</button>

      </div>
    </div>
  );
}

export default KeyInput;