/*
  Author : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 24/10/2023 17:30:31
*/
import React, { FC, useEffect, useState } from 'react';
import './Documentation.css';
import HeaderDocumentation from '../HeaderDocumentation/HeaderDocumentation';
import { useParams } from 'react-router-dom';
import { getDatas, getDatasBySlug } from '../../api/api-entity';


interface DocumentationProps {

}


const Documentation: FC<DocumentationProps> = () => {


  const { documentSlug, sectionSlug } = useParams()
  const [section, setSection] = useState<any>()


  useEffect(() => {
    window.scrollTo(0, 0)
    const runLocalData = async () => {
      console.log({ documentSlug, sectionSlug });
      if (documentSlug && sectionSlug) {
        const newDatas = await getDatasBySlug("documentation", documentSlug)
        if (newDatas.isSuccess) {
          const newSection : any = newDatas.result.sections.filter((s: any) => s.slug === sectionSlug)[0]
          console.log({newSection});
          
          setSection(newSection)
        }

      }else{
        const newDatas = await getDatas("documentation")
        console.log({newDatas});
        if (newDatas.isSuccess) {
          const newSection : any = newDatas.results[0].sections[0]
          console.log({"0":newDatas.results[0].sections});
          console.log({newSection});
          
          setSection(newSection)
        }
      }

    }
    runLocalData()
  },[documentSlug, sectionSlug])

  return (
    <div className="Documentation">
      <HeaderDocumentation />

      <div id="content-page" className="content-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">{section?.title}</h4>
                  </div>
                  <div className="iq-card-header-toolbar d-flex align-items-center">

                  </div>
                </div>
                <div className="iq-card-body">
                <div dangerouslySetInnerHTML={{ __html: section?.content }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Documentation;