/*
  Author : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 10/10/2023 16:53:31
*/
import React, { FC, useEffect } from 'react';
import './Profile.css';


interface ProfileProps {

}


const Profile: FC<ProfileProps> = () => {



  useEffect(() => {
    window.scrollTo(0, 0)
    const runLocalData = async () => {

    }
    runLocalData()
  })

  return (
    <div className="Profile">
      <div id="content-page" className="content-page">
        <div className="container-fluid">
          <div className="row profile-content">
            <div className="col-12 col-md-12 col-lg-4">
              <div className="iq-card">
                <div className="iq-card-body profile-page">
                  <div className="profile-header">
                    <div className="cover-container text-center">
                      <img src="../assets/images/user/1.jpg" alt="profile-bg" className="avatar-100 rounded-circle img-fluid" />
                      <div className="profile-detail mt-3">
                        <h3>Barry Tech</h3>
                        <p className="text-primary">Web designer</p>
                        <p>Phasellus faucibus mollis pharetra. Proin blandit ac massa.Morbi nulla dolor, ornare
                          at commodo non, feugiat non nisi.</p>
                      </div>
                      <div className="iq-social d-inline-block align-items-center">
                        <ul className="list-inline d-flex p-0 mb-0 align-items-center">
                          <li>
                            <a href="#" className="avatar-40 rounded-circle bg-primary mr-2 facebook"><i className="fa fa-facebook" aria-hidden="true" /></a>
                          </li>
                          <li>
                            <a href="#" className="avatar-40 rounded-circle bg-primary mr-2 twitter"><i className="fa fa-twitter" aria-hidden="true" /></a>
                          </li>
                          <li>
                            <a href="#" className="avatar-40 rounded-circle bg-primary mr-2 youtube"><i className="fa fa-youtube-play" aria-hidden="true" /></a>
                          </li>
                          <li>
                            <a href="#" className="avatar-40 rounded-circle bg-primary pinterest"><i className="fa fa-pinterest-p" aria-hidden="true" /></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between align-items-center mb-0">
                  <div className="iq-header-title">
                    <h4 className="card-title mb-0">Personal Details</h4>
                  </div>
                </div>
                <div className="iq-card-body">
                  <ul className="list-inline p-0 mb-0">
                    <li>
                      <div className="row align-items-center justify-content-between mb-3">
                        <div className="col-sm-6">
                          <h6>Birthday</h6>
                        </div>
                        <div className="col-sm-6">
                          <p className="mb-0">3rd March</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="row align-items-center justify-content-between mb-3">
                        <div className="col-sm-6">
                          <h6>Address</h6>
                        </div>
                        <div className="col-sm-6">
                          <p className="mb-0">Landon</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="row align-items-center justify-content-between mb-3">
                        <div className="col-sm-6">
                          <h6>Phone</h6>
                        </div>
                        <div className="col-sm-6">
                          <p className="mb-0">(010)987 543 201</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="row align-items-center justify-content-between mb-3">
                        <div className="col-sm-6">
                          <h6>Email</h6>
                        </div>
                        <div className="col-sm-6">
                          <p className="mb-0">Barry@example.com</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="row align-items-center justify-content-between mb-3">
                        <div className="col-sm-6">
                          <h6>Twitter</h6>
                        </div>
                        <div className="col-sm-6">
                          <p className="mb-0">@Barry</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="row align-items-center justify-content-between mb-3">
                        <div className="col-sm-6">
                          <h6>Facebook</h6>
                        </div>
                        <div className="col-sm-6">
                          <p className="mb-0">@Barry_Tech</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between align-items-center mb-0">
                  <div className="iq-header-title">
                    <h4 className="card-title mb-0">Skill Progress</h4>
                  </div>
                </div>
                <div className="iq-card-body">
                  <ul className="list-inline p-0 mb-0">
                    <li>
                      <div className="d-flex align-items-center justify-content-between mb-3">
                        <h6>Biography</h6>
                        <div className="iq-progress-bar-linear d-inline-block mt-1 w-50">
                          <div className="iq-progress-bar iq-bg-primary">
                            <span className="bg-primary" data-percent={70} />
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex align-items-center justify-content-between mb-3">
                        <h6>Horror</h6>
                        <div className="iq-progress-bar-linear d-inline-block mt-1 w-50">
                          <div className="iq-progress-bar iq-bg-danger">
                            <span className="bg-danger" data-percent={50} />
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex align-items-center justify-content-between mb-3">
                        <h6>Comic Book</h6>
                        <div className="iq-progress-bar-linear d-inline-block mt-1 w-50">
                          <div className="iq-progress-bar iq-bg-info">
                            <span className="bg-info" data-percent={65} />
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex align-items-center justify-content-between">
                        <h6>Adventure</h6>
                        <div className="iq-progress-bar-linear d-inline-block mt-1 w-50">
                          <div className="iq-progress-bar iq-bg-success">
                            <span className="bg-success" data-percent={85} />
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
            <div className="col-12 col-md-12 col-lg-8">
              <div className="row">
                <div className="col-md-6">
                  <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                    <div className="iq-card-header d-flex justify-content-between align-items-center mb-0">
                      <div className="iq-header-title">
                        <h4 className="card-title mb-0">Latest Uploads</h4>
                      </div>
                    </div>
                    <div className="iq-card-body">
                      <ul className="list-inline p-0 mb-0">
                        <li className="d-flex mb-4 align-items-center">
                          <div className="profile-icon bg-secondary"><span><i className="ri-file-3-fill" /></span>
                          </div>
                          <div className="media-support-info ml-3">
                            <h6>Documentation</h6>
                            <p className="mb-0">48kb</p>
                          </div>
                          <div className="iq-card-toolbar">
                            <div className="dropdown">
                              <span className="font-size-24" role="menu" id="dropdownMenuButton01" data-toggle="dropdown" aria-expanded="false">
                                <i className="ri-more-line" />
                              </span>
                              <div className="dropdown-menu dropdown-menu-right p-0">
                                <a className="dropdown-item" href="#"><i className="ri-user-unfollow-line mr-2" />Share</a>
                                <a className="dropdown-item" href="#"><i className="ri-close-circle-line mr-2" />Delete</a>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="d-flex mb-4 align-items-center">
                          <div className="profile-icon bg-secondary"><span><i className="ri-image-fill" /></span>
                          </div>
                          <div className="media-support-info ml-3">
                            <h6>Images</h6>
                            <p className="mb-0">204kb</p>
                          </div>
                          <div className="iq-card-toolbar">
                            <div className="dropdown">
                              <span className="font-size-24" role="menu" id="dropdownMenuButton02" data-toggle="dropdown" aria-expanded="false">
                                <i className="ri-more-line" />
                              </span>
                              <div className="dropdown-menu dropdown-menu-right p-0" style={{}}>
                                <a className="dropdown-item" href="#"><i className="ri-user-unfollow-line mr-2" />Share</a>
                                <a className="dropdown-item" href="#"><i className="ri-close-circle-line mr-2" />Delete</a>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="d-flex mb-4 align-items-center">
                          <div className="profile-icon bg-secondary"><span><i className="ri-video-fill" /></span>
                          </div>
                          <div className="media-support-info ml-3">
                            <h6>Videos</h6>
                            <p className="mb-0">509kb</p>
                          </div>
                          <div className="iq-card-toolbar">
                            <div className="dropdown">
                              <span className="font-size-24" role="menu" id="dropdownMenuButton03" data-toggle="dropdown" aria-expanded="false">
                                <i className="ri-more-line" />
                              </span>
                              <div className="dropdown-menu dropdown-menu-right p-0">
                                <a className="dropdown-item" href="#"><i className="ri-user-unfollow-line mr-2" />Share</a>
                                <a className="dropdown-item" href="#"><i className="ri-close-circle-line mr-2" />Delete</a>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="d-flex mb-4 align-items-center">
                          <div className="profile-icon bg-secondary"><span><i className="ri-file-3-fill" /></span>
                          </div>
                          <div className="media-support-info ml-3">
                            <h6>Resources</h6>
                            <p className="mb-0">48kb</p>
                          </div>
                          <div className="iq-card-toolbar">
                            <div className="dropdown">
                              <span className="font-size-24" role="menu" id="dropdownMenuButton04" data-toggle="dropdown" aria-expanded="false">
                                <i className="ri-more-line" />
                              </span>
                              <div className="dropdown-menu dropdown-menu-right p-0" style={{}}>
                                <a className="dropdown-item" href="#"><i className="ri-user-unfollow-line mr-2" />Share</a>
                                <a className="dropdown-item" href="#"><i className="ri-close-circle-line mr-2" />Delete</a>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="d-flex align-items-center">
                          <div className="profile-icon bg-secondary"><span><i className="ri-refresh-line" /></span>
                          </div>
                          <div className="media-support-info ml-3">
                            <h6>Celine Dion</h6>
                            <p className="mb-0">204kb</p>
                          </div>
                          <div className="iq-card-toolbar">
                            <div className="dropdown">
                              <span className="font-size-24" role="menu" id="dropdownMenuButton06" data-toggle="dropdown" aria-expanded="false">
                                <i className="ri-more-line" />
                              </span>
                              <div className="dropdown-menu dropdown-menu-right p-0">
                                <a className="dropdown-item" href="#"><i className="ri-user-unfollow-line mr-2" />Share</a>
                                <a className="dropdown-item" href="#"><i className="ri-close-circle-line mr-2" />Delete</a>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                    <div className="iq-card-header d-flex justify-content-between align-items-center mb-0">
                      <div className="iq-header-title">
                        <h4 className="card-title mb-0">Top Books</h4>
                      </div>
                    </div>
                    <div className="iq-card-body">
                      <ul className="list-inline p-0 mb-0">
                        <li>
                          <div className="iq-details mb-2">
                            <span className="title">Adventure</span>
                            <div className="percentage float-right text-primary">95 <span>%</span></div>
                            <div className="iq-progress-bar-linear d-inline-block w-100">
                              <div className="iq-progress-bar">
                                <span className="bg-primary" data-percent={95} />
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="iq-details mb-2">
                            <span className="title">Horror</span>
                            <div className="percentage float-right text-warning">72 <span>%</span></div>
                            <div className="iq-progress-bar-linear d-inline-block w-100">
                              <div className="iq-progress-bar">
                                <span className="bg-warning" data-percent={72} />
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="iq-details mb-2">
                            <span className="title">Comic Book</span>
                            <div className="percentage float-right text-info">75 <span>%</span></div>
                            <div className="iq-progress-bar-linear d-inline-block w-100">
                              <div className="iq-progress-bar">
                                <span className="bg-info" data-percent={75} />
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="iq-details mb-2">
                            <span className="title">Biography</span>
                            <div className="percentage float-right text-danger">70 <span>%</span></div>
                            <div className="iq-progress-bar-linear d-inline-block w-100">
                              <div className="iq-progress-bar">
                                <span className="bg-danger" data-percent={70} />
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="iq-details">
                            <span className="title">Mystery</span>
                            <div className="percentage float-right text-success">80 <span>%</span></div>
                            <div className="iq-progress-bar-linear d-inline-block w-100">
                              <div className="iq-progress-bar">
                                <span className="bg-success" data-percent={80} />
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between align-items-center mb-0">
                  <div className="iq-header-title">
                    <h4 className="card-title mb-0">Daily Sales</h4>
                  </div>
                  <div className="iq-card-header-toolbar d-flex align-items-center">
                    <div className="dropdown">
                      <span className="dropdown-toggle text-primary" id="dropdownMenuButton05" data-toggle="dropdown">
                        <i className="ri-more-fill" />
                      </span>
                      <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton05">
                        <a className="dropdown-item" href="#"><i className="ri-eye-fill mr-2" />View</a>
                        <a className="dropdown-item" href="#"><i className="ri-delete-bin-6-fill mr-2" />Delete</a>
                        <a className="dropdown-item" href="#"><i className="ri-pencil-fill mr-2" />Edit</a>
                        <a className="dropdown-item" href="#"><i className="ri-printer-fill mr-2" />Print</a>
                        <a className="dropdown-item" href="#"><i className="ri-file-download-fill mr-2" />Download</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="iq-card-body">
                  <ul className="perfomer-lists m-0 p-0">
                    <li className="d-flex mb-4 align-items-center">
                      <div className="user-img img-fluid"><img className="img-fluid avatar-50 rounded-circle" src="../assets/images/user/06.jpg" alt="" /></div>
                      <div className="media-support-info ml-3">
                        <h5>Reading on the World</h5>
                        <p className="mb-0">Lorem Ipsum is simply dummy test</p>
                      </div>
                      <div className="iq-card-header-toolbar d-flex align-items-center">
                        <span className="text-dark"><b>+$82</b></span>
                      </div>
                    </li>
                    <li className="d-flex mb-4 align-items-center">
                      <div className="user-img img-fluid"><img className="img-fluid avatar-50 rounded-circle" src="../assets/images/user/07.jpg" alt="" /></div>
                      <div className="media-support-info ml-3">
                        <h5>Little Black Book</h5>
                        <p className="mb-0">Lorem Ipsum is simply dummy test</p>
                      </div>
                      <div className="iq-card-header-toolbar d-flex align-items-center">
                        <span className="text-dark"><b>+$90</b></span>
                      </div>
                    </li>
                    <li className="d-flex align-items-center">
                      <div className="user-img img-fluid"><img className="img-fluid avatar-50 rounded-circle" src="../assets/images/user/08.jpg" alt="" /></div>
                      <div className="media-support-info ml-3">
                        <h5>See the More Story</h5>
                        <p className="mb-0">Lorem Ipsum is simply dummy test</p>
                      </div>
                      <div className="iq-card-header-toolbar d-flex align-items-cener">
                        <span className="text-dark"><b>+$85</b></span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between align-items-center mb-0">
                  <div className="iq-header-title">
                    <h4 className="card-title mb-0">Top Products</h4>
                  </div>
                  <div className="iq-card-header-toolbar d-flex align-items-center">
                    <div className="dropdown">
                      <span className="dropdown-toggle text-primary" id="dropdownMenuButton07" data-toggle="dropdown">
                        <i className="ri-more-fill" />
                      </span>
                      <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton05">
                        <a className="dropdown-item" href="#"><i className="ri-eye-fill mr-2" />View</a>
                        <a className="dropdown-item" href="#"><i className="ri-delete-bin-6-fill mr-2" />Delete</a>
                        <a className="dropdown-item" href="#"><i className="ri-pencil-fill mr-2" />Edit</a>
                        <a className="dropdown-item" href="#"><i className="ri-printer-fill mr-2" />Print</a>
                        <a className="dropdown-item" href="#"><i className="ri-file-download-fill mr-2" />Download</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="iq-card-body">
                  <ul className="perfomer-lists m-0 p-0">
                    <li className="row mb-3 align-items-center justify-content-between">
                      <div className="col-md-8">
                        <h5>Find The Wave Book</h5>
                        <p className="mb-0">General Book</p>
                      </div>
                      <div className="col-md-4 text-right">
                        <div className="iq-card-header-toolbar d-flex align-items-center">
                          <span className="text-primary mr-3"><b>+$92</b></span>
                          <div className="iq-progress-bar-linear d-inline-block mt-1 w-100">
                            <div className="iq-progress-bar iq-bg-primary">
                              <span className="bg-primary" data-percent={92} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="row align-items-center justify-content-between">
                      <div className="col-md-8">
                        <h5>A man with those Faces</h5>
                        <p className="mb-0">Biography Book</p>
                      </div>
                      <div className="col-md-4 text-right">
                        <div className="iq-card-header-toolbar d-flex align-items-center">
                          <span className="text-danger mr-3"><b>+$85</b></span>
                          <div className="iq-progress-bar-linear d-inline-block mt-1 w-100">
                            <div className="iq-progress-bar iq-bg-primary">
                              <span className="bg-danger" data-percent={85} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Profile;