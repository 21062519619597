/*
  Author : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 25/10/2023 13:50:51
*/
import React, { FC, useEffect } from 'react';
import './Home.css';
import { HeaderHome } from '../../pages/HeaderHome';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getAuthState } from '../../redux/selectors/selectors';



interface HomeProps {

}


const Home: FC<HomeProps> = () => {

  const { t } = useTranslation()
  const isAuth = useSelector(getAuthState)


  useEffect(() => {
    window.scrollTo(0, 0)
    const runLocalData = async () => {

    }
    runLocalData()
  })

  return (
    <div className="Home">
      <HeaderHome />
      <div className="home-page">
        {/* Home Component */}
        <main>
          <section className="hero" style={{ backgroundImage: `url('/assets/images/login/login.jpg')` }}>
            <h1>{t('title')}</h1>
            <p>{t('sub_title')}</p>
            {
              !isAuth ?
                <>
                  <Link to="/signup">{t('sign_up')}</Link>
                  <Link to="/signin">{t('sign_in')}</Link>
                </>
                :
                <>
                  <Link to="/dashboard/movie-list">{t('studio')}</Link>
                </>
            }
          </section>

          

          <section className="subscription">
            <h2>{t('subscribe_now')}</h2>
            <p>{t('subscribe_description')}</p>
            <Link to="/pricing">{t('pricing')}</Link>
          </section>
        </main>



        <footer className="footer">
          <div className="container">
            <div className="footer-content row">
              <div className="footer-section about col-md-3">
                <h2>Ouitube</h2>
                <p>{t('description')}
                  
                </p>
              </div>
              <div className="footer-section about col-md-3">
                <h2>{t('about_title')}</h2>
                <p>{t('about_description')}
                </p>
              </div>

              <div className="footer-section links col-md-3">
                <h2>{t('quick_link')}</h2>
                <ul>
                  <li><Link to="/about">{t('About')}</Link></li>
                  <li><Link to="/pricing">{t('pricing')}</Link></li>
                  <li><Link to="/contact">{t('contact')}</Link></li>
                </ul>
              </div>

              <div className="footer-section contact col-md-3">
                <h2>{t('contact_us')}</h2>
                <address>
                  <p>{t('Address')} : 123, Rue de la Vidéo</p>
                  <p>{t('Email')} : contact@ouitube.fr</p>
                </address>
              </div>
            </div>
          </div>

          <div className="footer-bottom">
            <p>&copy; {t('copyright')}</p>
          </div>
        </footer>

      </div>
    </div>
  );
}

export default Home;