/*
  Author : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 15/10/2023 10:42:10
*/
import React, { FC, useEffect, useRef, useState } from 'react';
import './SelectData.css';
import { getDatas } from '../../api/api-entity';
import $ from 'jquery'; // Importez jQuery
import 'select2'; // Importez Select2
import 'select2/dist/css/select2.css'; // Importez les styles de Select2


interface SelectEmailDataProps {
  entityName: string
  defaultValue?: string
  handleChange: (value: string) => void
}


const SelectData: FC<SelectEmailDataProps> = ({entityName,defaultValue,handleChange}) => {

  const select2Ref = useRef<any>();
  const [datas, setDatas] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    window.scrollTo(0, 0);
   ( $(select2Ref?.current) as any).select2();
    const runLocalData = async () => {
      const emailData = await getDatas(entityName)
      if (emailData.isSuccess) {
        setDatas(emailData.results)
        if(!defaultValue && emailData.results[0]){
          handleChange(emailData.results[0].code || emailData.results[0]._id)
        }else if(defaultValue){
          handleChange(defaultValue)

        }
      }
      setIsLoading(false)
    }
    runLocalData()
  },[isLoading])

  return (
    <div className="SelectData">
      {
        entityName === "email_data" ?
        <select  
        ref={select2Ref} className='form-control' onChange={(e)=>handleChange(e.target.value)}  name="data" >
          {
            datas.map((data) => {
              return <option key={data._id} value={data.code}>
                {data.title} ({data.code})
              </option>
            })
          }
        </select>
        :
        null
      }
      {
        entityName === "video" ?
        <select  ref={select2Ref} 
        className='form-control' 
        onChange={(e)=>handleChange(e.target.value)}  
        name="data" >
          {
            datas.map((data) => {
              return <option key={data._id} value={data.code}>
                {data.name}
              </option>
            })
          }
        </select>
        :
        null
      }
    </div>
  );
}

export default SelectData;