/*
  Author : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 24/10/2023 17:32:12
*/
import React, { FC, useEffect, useState } from 'react';
import './HeaderDocumentation.css';
import { Link } from 'react-router-dom';
import SwitchTheme from '../SwitchTheme/SwitchTheme';
import { getDatas } from '../../api/api-entity';
import { useTranslation } from 'react-i18next';


interface HeaderDocumentationProps {

}


const HeaderDocumentation: FC<HeaderDocumentationProps> = () => {

  const { t} = useTranslation()
  const [datas, setDatas] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    window.scrollTo(0, 0)
    const runLocalData = async () => {
      const newData: any = await getDatas("documentation")
      if (newData.isSuccess) {
        setDatas(newData.results)
      }
      setIsLoading(false)
    }
    runLocalData()
  }, [isLoading])

  console.log({datas});
  

  return (
    <div className="HeaderDashboard shadow">
      <div className="wrapper">
        {/* Sidebar  */}
        <div className="iq-sidebar shadow">
          <div className="iq-sidebar-logo d-flex justify-content-between">
            <Link to="" className="HeaderDashboard-logo">
              <img src="/assets/images/logo.png" className="img-fluid rounded-normal" alt="" />
              <div className="logo-title">
                <span className="text-primary text-uppercase">Ouitube</span>
              </div>
            </Link>
            <div className="iq-menu-bt-sidebar">
              <div className="iq-menu-bt align-self-center">
                <div className="wrapper-menu">
                  <div className="main-circle"><i className="las la-bars" /></div>
                </div>
              </div>
            </div>
          </div>
          <div id="sidebar-scrollbar">
            <nav className="iq-sidebar-menu">
              <ul id="iq-sidebar-toggle" className="iq-menu">
              <li>
                <Link to="/" className="iq-waves-effect collapsed"
                  data-toggle="collapse" aria-expanded="false">
                  <i className="las la-list-ul" />
                  <span>
                    {t('home')}
                    </span>
                  <i className="ri-arrow-right-s-line iq-arrow-right" />
                </Link>

              </li>
                {
                  datas?.length ?

                    datas?.map((data: any) => {
                      return <li key={data._id}>
                        <a href={"#" + data.slug} className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false"><i className="las la-list-ul" /><span>
                          {data.title}
                        </span><i className="ri-arrow-right-s-line iq-arrow-right" /></a>
                        <ul id={data.slug} className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                          {
                            data?.sections?.map((section: any, index: number)=>{
                              return  <li key={index}>
                              <Link to={"/documentation/"+data.slug+"/"+section.slug}><i className="las la-eye" />
                                {section.title}
                              </Link>
                            </li>
                            })
                          }
                         

                        </ul>
                      </li>
                    })
                    :
                    null
                }


              </ul>
            </nav>
          </div>
        </div>
        {/* TOP Nav Bar */}
        <div className="iq-top-navbar shadow">
          <div className="iq-navbar-custom">
            <nav className="navbar navbar-expand-lg navbar-light p-0">
              <div className="iq-menu-bt d-flex align-items-center">
                <div className="wrapper-menu">
                  <div className="main-circle"><i className="las la-bars" /></div>
                </div>
                <div className="iq-navbar-logo d-flex justify-content-between">
                  <a href="index" className="HeaderDashboard-logo">
                    <img src="/assets/images/logo.png" className="img-fluid rounded-normal" alt="" />
                    <div className="logo-title">
                      <span className="text-primary text-uppercase">Ouitube</span>
                    </div>
                  </a>
                </div>
              </div>
              <div className="navbar-breadcrumb">
                <h4 className="mb-0 text-dark">Docs</h4>

              </div>
              <div className="iq-search-bar ml-auto">
                <form action="#" className="searchbox">
                  <input type="text" className="text search-input border" placeholder="Search Here..." />
                  <a className="search-link" href="#"><i className="ri-search-line" /></a>
                </form>
              </div>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-label="Toggle navigation">
                <i className="ri-menu-3-line" />
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto navbar-list">
                  {/* <li className="nav-item nav-icon search-content">
                  <a href="#" className="search-toggle iq-waves-effect text-gray rounded">
                    <i className="ri-search-line" />
                  </a>
                  <form action="#" className="search-box p-0">
                    <input type="text" className="text search-input" placeholder="Type here to search..." />
                    <a className="search-link" href="#"><i className="ri-search-line" /></a>
                  </form>
                </li> */}
                  <li className="nav-item nav-icon">
                    <a href="#">
                      <SwitchTheme />
                    </a>
                  </li>
                  {/* <li className="nav-item nav-icon">
                  <a href="#" className="search-toggle iq-waves-effect text-gray rounded">
                    <i className="ri-notification-2-fill" />
                    <span className="bg-primary dots" />
                  </a>
                  <div className="iq-sub-dropdown">
                    <div className="iq-card shadow-none m-0">
                      <div className="iq-card-body p-0">
                        <div className="bg-primary p-3">
                          <h5 className="mb-0 text-white">All Notifications<small className="badge  badge-light float-right pt-1">4</small></h5>
                        </div>
                        <a href="#" className="iq-sub-card">
                          <div className="media align-items-center">
                            <div className="">
                              <img className="avatar-40 rounded" src="/assets/images/user/01.jpg" alt="" />
                            </div>
                            <div className="media-body ml-3">
                              <h6 className="mb-0 ">Emma Watson Barry</h6>
                              <small className="float-right font-size-12">Just Now</small>
                              <p className="mb-0">95 MB</p>
                            </div>
                          </div>
                        </a>
                        <a href="#" className="iq-sub-card">
                          <div className="media align-items-center">
                            <div className="">
                              <img className="avatar-40 rounded" src="/assets/images/user/02.jpg" alt="" />
                            </div>
                            <div className="media-body ml-3">
                              <h6 className="mb-0 ">New customer is join</h6>
                              <small className="float-right font-size-12">5 days ago</small>
                              <p className="mb-0">Cyst Barry</p>
                            </div>
                          </div>
                        </a>
                        <a href="#" className="iq-sub-card">
                          <div className="media align-items-center">
                            <div className="">
                              <img className="avatar-40 rounded" src="/assets/images/user/03.jpg" alt="" />
                            </div>
                            <div className="media-body ml-3">
                              <h6 className="mb-0 ">Two customer is left</h6>
                              <small className="float-right font-size-12">2 days ago</small>
                              <p className="mb-0">Cyst Barry</p>
                            </div>
                          </div>
                        </a>
                        <a href="#" className="iq-sub-card">
                          <div className="media align-items-center">
                            <div className="">
                              <img className="avatar-40 rounded" src="/assets/images/user/04.jpg" alt="" />
                            </div>
                            <div className="media-body ml-3">
                              <h6 className="mb-0 ">New Mail from Fenny</h6>
                              <small className="float-right font-size-12">3 days ago</small>
                              <p className="mb-0">Cyst Barry</p>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item nav-icon dropdown">
                  <a href="#" className="search-toggle iq-waves-effect text-gray rounded">
                    <i className="fa fa-envelope" aria-hidden="true" />
                    <span className="bg-primary count-mail" />
                  </a>
                  <div className="iq-sub-dropdown">
                    <div className="iq-card shadow-none m-0">
                      <div className="iq-card-body p-0 ">
                        <div className="bg-primary p-3">
                          <h5 className="mb-0 text-white">All Messages<small className="badge  badge-light float-right pt-1">5</small></h5>
                        </div>
                        <a href="#" className="iq-sub-card">
                          <div className="media align-items-center">
                            <div className="">
                              <img className="avatar-40 rounded" src="/assets/images/user/01.jpg" alt="" />
                            </div>
                            <div className="media-body ml-3">
                              <h6 className="mb-0 ">Barry Emma Watson</h6>
                              <small className="float-left font-size-12">13 Jun</small>
                            </div>
                          </div>
                        </a>
                        <a href="#" className="iq-sub-card">
                          <div className="media align-items-center">
                            <div className="">
                              <img className="avatar-40 rounded" src="/assets/images/user/02.jpg" alt="" />
                            </div>
                            <div className="media-body ml-3">
                              <h6 className="mb-0 ">Lorem Ipsum Watson</h6>
                              <small className="float-left font-size-12">20 Apr</small>
                            </div>
                          </div>
                        </a>
                        <a href="#" className="iq-sub-card">
                          <div className="media align-items-center">
                            <div className="">
                              <img className="avatar-40 rounded" src="/assets/images/user/03.jpg" alt="" />
                            </div>
                            <div className="media-body ml-3">
                              <h6 className="mb-0 ">Why do we use it?</h6>
                              <small className="float-left font-size-12">30 Jun</small>
                            </div>
                          </div>
                        </a>
                        <a href="#" className="iq-sub-card">
                          <div className="media align-items-center">
                            <div className="">
                              <img className="avatar-40 rounded" src="/assets/images/user/04.jpg" alt="" />
                            </div>
                            <div className="media-body ml-3">
                              <h6 className="mb-0 ">Variations Passages</h6>
                              <small className="float-left font-size-12">12 Sep</small>
                            </div>
                          </div>
                        </a>
                        <a href="#" className="iq-sub-card">
                          <div className="media align-items-center">
                            <div className="">
                              <img className="avatar-40 rounded" src="/assets/images/user/05.jpg" alt="" />
                            </div>
                            <div className="media-body ml-3">
                              <h6 className="mb-0 ">Lorem Ipsum generators</h6>
                              <small className="float-left font-size-12">5 Dec</small>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </li> */}

                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderDocumentation;