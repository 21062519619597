/*
  Author : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 13/10/2023 15:50:36
*/
import React, { FC, useEffect, useState } from 'react';
import './DataTable.css';
import { Link } from 'react-router-dom';
import { getKeys } from '../../../helpers/manageRoutes';
import MovieList from '../../../components/templates/MovieList/MovieList';
import { formattedDate, generateId } from '../../../helpers/utils';


interface DataTableProps {
  data: any
  entityName: string
  handleDelete: (event: any, data: any) => void
}


const DataTable: FC<DataTableProps> = ({ entityName, data, handleDelete }) => {

  const [fields, setFields] = useState<string[]>([])

  useEffect(() => {
    window.scrollTo(0, 0)
    const runLocalData = async () => {
      setFields(getKeys(data.results.length ? data.results[0] : []))
      console.log({data});
    }
    runLocalData()
  }, [entityName, data])


  
  

  return (
    <div className="DataTable">
      {
        entityName === "movie" ?
          <MovieList
            entityName={entityName}
            data={data}
            handleDelete={handleDelete}
          />
          :
          <div className="iq-card-body">
            <div className="table-view">
              <table className="data-tables table movie_table " style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th style={{ width: '10%' }}>No</th>
                    {
                      fields.map((field: string) => {
                        return <th key={generateId()} style={{ width: '20%' }}>{field}</th>
                      })
                    }
                    <th style={{ width: '20%' }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.results?.map((value: any, index: number) => {
                      return <tr key={value?._id}>
                        <td>{index + 1}</td>
                        {
                          fields.map((field: string) => {
                            if (["created_at", "updated_at", "started_at"].includes(field)) {
                              return <td key={generateId()} style={{ width: '20%' }}>
                                {formattedDate(value?.[field])}
                              </td>
                            }
                            return <td key={generateId()} style={{ width: '20%' }}>
                              {typeof value?.[field] === "string" ? value?.[field] : ""}
                            </td>
                          })
                        }

                        <td>
                          <div className="flex align-items-center list-user-action">
                            <Link className="iq-bg-warning" data-toggle="tooltip" data-placement="top" title="" data-original-title="View" to={"/dashboard/view/" + entityName + "/" + value._id}><i className="lar la-eye" /></Link>
                            <Link className="iq-bg-success" data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" to={"/dashboard/update/" + entityName + "/" + value._id}><i className="ri-pencil-line" /></Link>
                            <a onClick={(event) => handleDelete(event, value)} className="iq-bg-primary" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" href="#"><i className="ri-delete-bin-line" /></a>
                          </div>
                        </td>
                      </tr>
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
      }
    </div>
  );
}

export default DataTable;