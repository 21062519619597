/*
  Author : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 16/10/2023 17:32:47
*/
import React, { FC, useEffect, useState } from 'react';
import './VideoItem.css';
import { Video } from '../../models/Video';
import { formatDuration, formattedDate } from '../../helpers/utils';
import { useDispatch } from 'react-redux';
import { ADD_TO_STORAGE } from '../../redux/actions/actionTypes';
import { Navigate } from 'react-router-dom';



interface VideoItemProps {
  video: Video
}


const VideoItem: FC<VideoItemProps> = ({ video }) => {

  const dispatch = useDispatch()
  const [navigate, setNavigate] = useState<boolean>(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    const runLocalData = async () => {

    }
    runLocalData()
  })

  const handleSelect = () =>{
    dispatch({
      type: ADD_TO_STORAGE,
      key: 'currentVideo',
      unique: true,
      payload: video
    })
    setNavigate(true)
  }

  if(navigate){
    return <Navigate to={"/dashboard/watch?v="+video.uniqueCode} />
  }

  return (<div className="VideoItem border  mb-1" onClick={handleSelect}>
        <div className="media align-items-center">
          <div className="iq-movie">
            <a href="#">
              <img src={video?.posterFiles?.[0]}
                className="img-border-radius img-fluid"
                width="150px"
                height="50px"
                alt=""
              />
            </a>
          </div>
          <div className="media-body text-white text-left ml-3">
            <p className="mb-0 video-name">
              {video.name}
            </p> 
            <small>
              Durée : {formatDuration(video?.duration)}
            </small> 
            <small>
              Mise en ligne le {formattedDate(video.created_at)}
            </small>
          </div>
        </div>
      </div>
  );
}

export default VideoItem;