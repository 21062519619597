
import SwitchTheme from '../components/SwitchTheme/SwitchTheme'
import LanguageSelector from '../components/LanguageSelector/LanguageSelector'
import './HeaderHome.css'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getAuthState } from '../redux/selectors/selectors'
import { LOGOUT } from '../redux/actions/actionTypes'
import { useDispatch } from 'react-redux'

export const HeaderHome: React.FC = () => {
  const { t } = useTranslation()
  const isAuth = useSelector(getAuthState)
  const dispatch = useDispatch()


  const handleLogout = (event: any) => {
    event.preventDefault()
    dispatch({
      type: LOGOUT,
      payload: null
    })
  }

  return (
    <div className="HeaderHome">
      <div className="iq-top-navbar shadow">
        <div className="iq-navbar-custom">
          <nav className="navbar navbar-expand-lg navbar-light p-0">
            <div className="iq-menu-bt d-flex align-items-center">
              <div className="wrapper-menu">
                <div className="main-circle"><i className="las la-bars" /></div>
              </div>
              <div className="iq-navbar-logo d-flex justify-content-between">
                <a href="index" className="HeaderDashboard-logo">
                  <img src="/assets/images/logo.png" className="img-fluid rounded-normal" alt="" />
                  <div className="logo-title">
                    <span className="text-primary text-uppercase">Ouitube</span>
                  </div>
                </a>
              </div>
            </div>
            <div className="navbar-breadcrumb">
              <h4 className="mb-0 text-dark">Ouitube</h4>
            </div>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <div className="menu-main-menu-container">
                <ul id="top-menu" className="navbar-nav ml-auto">
                  <li className="menu-item">
                    <Link to="/" className="iq-waves-effect collapsed"
                      data-toggle="collapse" aria-expanded="false">
                      <span> {t('home')}</span>
                      <i className="ri-arrow-right-s-line iq-arrow-right" />
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/documentation" className="iq-waves-effect collapsed"
                      data-toggle="collapse" aria-expanded="false">
                      <span> {t('documentation')}</span>
                      <i className="ri-arrow-right-s-line iq-arrow-right" />
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/choose-plan" className="iq-waves-effect collapsed"
                      data-toggle="collapse" aria-expanded="false">
                      <span> {t('pricing')}</span>
                      <i className="ri-arrow-right-s-line iq-arrow-right" />
                    </Link>
                  </li>
                  {
                !isAuth ?
                  <>
                    <li className="menu-item">
                      <Link to="/signup" className="iq-waves-effect collapsed"
                        data-toggle="collapse" aria-expanded="false">
                        <span>{t('sign_up')}</span>
                        <i className="ri-arrow-right-s-line iq-arrow-right" />
                      </Link>

                    </li>
                    <li className="menu-item">
                      <Link to="/signin" className="iq-waves-effect collapsed"
                        data-toggle="collapse" aria-expanded="false">
                        <span>{t('sign_in')}</span>
                        <i className="ri-arrow-right-s-line iq-arrow-right" />
                      </Link>

                    </li>
                  </>
                  :
                  <>
                    <li className="menu-item">
                      <Link to='/dashboard/movie-list' className="iq-waves-effect collapsed"
                        data-toggle="collapse" aria-expanded="false">
                        <span>{t('studio')}</span>
                        <i className="ri-arrow-right-s-line iq-arrow-right" />
                      </Link>

                    </li>
                    <li className="menu-item">
                      <a href='#' onClick={handleLogout} className="iq-waves-effect collapsed"
                        data-toggle="collapse" aria-expanded="false">
                        <span>{t('logout')}</span>
                        <i className="ri-arrow-right-s-line iq-arrow-right" />
                      </a>

                    </li>
                  </>
              }

                </ul>
              </div>
            </div>

           
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-label="Toggle navigation">
              <i className="ri-menu-3-line" />
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto navbar-list">
                <li className="nav-item nav-icon search-content">
                  <a href="#" className="search-toggle iq-waves-effect text-gray rounded">
                    <i className="ri-search-line" />
                  </a>
                  <form action="#" className="search-box p-0">
                    <input type="text" className="text search-input" placeholder="Type here to search..." />
                    <a className="search-link" href="#"><i className="ri-search-line" /></a>
                  </form>
                </li>

                <li className="nav-item nav-icon">
                  <a href="#">
                    <SwitchTheme />
                  </a>
                </li>
                <li className="nav-item nav-icon d-flex align-items-center">
                  <LanguageSelector />
                </li>
              
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  )
}
