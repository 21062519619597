/*
  Author : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 10/10/2023 17:14:16
*/
import React, { FC, useEffect, useState } from 'react';
import './MovieList.css';
import { Video } from '../../../models/Video';
import { formatDuration, formattedDate } from '../../../helpers/utils';
import { Link } from 'react-router-dom';
import ImagePreviewComponent from '../../ImagePreviewComponent/ImagePreviewComponent';


interface MovieListProps {
  data: any,
  entityName?: string
  handleDelete: (event: any, data: any) => void
}


const MovieList: FC<MovieListProps> = ({ entityName, data, handleDelete }) => {


  const [isPreview, setPreview] = useState<any[]>([])

  useEffect(() => {
    window.scrollTo(0, 0)
    const runLocalData = async () => {

    }
    runLocalData()
  })

  return (
    <>
      {
        isPreview.length > 0 &&
        (<ImagePreviewComponent
          imageUrls={isPreview}
          close={() => setPreview([])}
        />)
      }
      <div className="iq-card-body">
        <div className="table-view">
          <table className="data-tables table movie_table " style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Movie</th>
                <th style={{ width: '20%' }}>Description</th>
                <th>Quality</th>
                <th>Release Year</th>
                <th>Language</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                data.results.map((value: Video) => {
                  return <tr key={value!._id}>
                    <td>
                      <div className="media align-items-center">
                        <div className="iq-movie">
                          <a href="#">
                            <img src={value?.posterFiles?.[0]} className="img-border-radius img-fluid" width="150" height="50" alt="" onClick={() => setPreview(value.posterFiles as string[])} />
                          </a>
                        </div>
                        <div className="media-body text-white text-left ml-3">
                          <Link to={"/dashboard/watch?v=" + value.uniqueCode} className="mb-0">
                            {value.name}
                          </Link> <br />
                          <small>
                            {formatDuration(value?.duration)}
                          </small> -
                          <small>
                            Mise en ligne le {formattedDate(value.created_at)}
                          </small>
                        </div>

                      </div>
                    </td>
                    <td>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <div className="flex align-items-center list-user-action">
                        <Link className="iq-bg-warning" data-toggle="tooltip" data-placement="top" title="" data-original-title="View" to={"/dashboard/view/" + entityName + "/" + value._id}> <i className="lar la-eye" /></Link>
                        <Link className="iq-bg-success" data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" to={"/dashboard/update/" + entityName + "/" + value._id}><i className="ri-pencil-line" /></Link>
                        <a onClick={(event) => handleDelete(event, value)} className="iq-bg-primary" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" href="#"><i className="ri-delete-bin-line" /></a>
                      </div>
                    </td>
                  </tr>
                })
              }
            </tbody>
          </table>
        </div>
      </div>

      {/* <div className="iq-Data">
          <a href="#">
            <img src={data?.posterFiles?.[0]}
              className="img-border-radius img-fluid"
              width="150"
              height="50"
              alt=""
            />
          </a>
        </div>
        <div className="media-body text-white text-left ml-3">
          <p className="mb-0">{data.name}</p>
          <small>{formatDuration(data?.duration)}</small> -
          <small>Mise en ligne le {formattedDate(data.created_at)}</small>
        </div> */}
    </>
  );
}

export default MovieList;