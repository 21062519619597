import { Application } from "../models/Application";
import { Category } from "../models/Category";
import { Channel } from "../models/Channel";
import { Client } from "../models/Client";
import { Documentation } from "../models/Documentation";
import { EmailEvent } from "../models/EmailEvent";
import { Formule } from "../models/Formule";
import { Meta } from "../models/Meta";
import { OauthClient } from "../models/OauthClient";
import { Resolution } from "../models/Resolution";
import { User } from "../models/User";
import { Video } from "../models/Video";
import { Webinaire } from "../models/Webinaire";
import { authstrategy } from "../models/authstrategy";
import { email_template } from "../models/emailTemplate";
import { email_paramater } from "../models/email_paramater";
import { getFieldsFromInterface } from "./utils";

export const getFields = (interfaceName: string) => {

    if (interfaceName.toLocaleLowerCase() === "movie") {
        const video: Video = {
            name: "",
            description: ""
        }
        return getFieldsFromInterface(video)
    }

    if (interfaceName.toLocaleLowerCase() === "category") {
        const category: Category = {
            name: "",
            description: ""
        }
        return getFieldsFromInterface(category)
    }

    if (interfaceName.toLocaleLowerCase() === "channel") {
        const channel: Channel = {
            name: "",
            description: ""
        }
        return getFieldsFromInterface(channel)
    }

    if (interfaceName.toLocaleLowerCase() === "application") {
        const application: Application = {
            name: "",
            description: ""
        }
        return getFieldsFromInterface(application)
    }

    if (interfaceName.toLocaleLowerCase() === "oauthclient") {
        const application: OauthClient = {
            clientName: "",
            description: "",
            origins: [],
        }
        return getFieldsFromInterface(application)
    }
    if (interfaceName.toLocaleLowerCase() === "email_template") {
        const application: email_template = {
            title: "",
            subject: "",
            content: ""
        }
        return getFieldsFromInterface(application)
    }
    if (interfaceName.toLocaleLowerCase() === "meta") {
        const application: Meta = {
            name: "",
            value: ""
        }
        return getFieldsFromInterface(application)
    }
    if (interfaceName.toLocaleLowerCase() === "email_paramater") {
        const application: email_paramater = {
            name: "",
            username: "",
            password: "",
            output_server: "",
            port_output_server: "",
            input_server: "",
            port_input_server: "",
            protocol: "",
        }
        return getFieldsFromInterface(application)
    }
    if (interfaceName.toLocaleLowerCase() === "user") {
        const user: User = {
            fullName: "",
            email: "",
            password: "",
        }
        return getFieldsFromInterface(user)
    }
    if (interfaceName.toLocaleLowerCase() === "emailevent") {
        const emailevent: EmailEvent = {
            name: "",
            template: "",
        }
        return getFieldsFromInterface(emailevent)
    }
    if (interfaceName.toLocaleLowerCase() === "webinaire") {
        const webinaire: Webinaire = {
            title: "",
            description: "",
            started_at: "",
            duration: "",
            video: "",
        }
        return getFieldsFromInterface(webinaire)
    }
    if (interfaceName.toLocaleLowerCase() === "authstrategy") {
        const newauthstrategy : authstrategy = {
            name: "",
            description: "",
            clientId: "",
            clientSecret: "",
            callbackURL: "",
        }
        return getFieldsFromInterface(newauthstrategy)
    }
    if (interfaceName.toLocaleLowerCase() === "formule") {
        const formule : Formule = {
            name: "",
            description: "",
            'storage_size': "",
            'storage_limit': "",
            annually_price: 0,
            monthly_price: 0,
            options: [],
        }
        return getFieldsFromInterface(formule)
    }
    if (interfaceName.toLocaleLowerCase() === "documentation") {
        const documentation : Documentation = {
            title: "",
            description: "",
            sections: [],
        }
        return getFieldsFromInterface(documentation)
    }
    if (interfaceName.toLocaleLowerCase() === "resolution") {
        const resolution : Resolution = {
            name: "",
            size: ""
        }
        return getFieldsFromInterface(resolution)
    }
    if (interfaceName.toLocaleLowerCase() === "client") {
        const client : Client = {
            clientIp: "",
            browser: "",
            os: "",
            device: "",
            acceptLanguage: "",
            country: "",
            city: "",
            latitude: "",
            longitude: "",
            origin: ""
        }
        return getFieldsFromInterface(client)
    }

    return []
}

export const removeStringFromArray = (array: string[], target: string): string[] => {
    return array.filter(item => item.toLowerCase() !== target.toLowerCase());
}
export const getKeys = (data: any) => {


    try {
        let result = Object.keys(data)
        const excludes = ['_id',"PASSWORD", "content", "FILEURLS", "CREATED_AT", "UPDATED_AT", '_V', "clientid", "CLIENTSECRET", "AUTHOR", "__V", "GRANTS", "REDIRECTURIS",
        'FRIEND_COUNT',	'IS_MY_FRIEND',	'REQUEST_RECEIVED',	'REQUEST_SENDED',	'ROLES',	"ADDRESSES",	"TAGS",	"ONLINE",	"ISVERIFIED",	"RECEIVEPROMOMESSAGE",
        "CREATED_FORMATTED_WITH_TIME_SINCE"]
    
        excludes.forEach(elt => {
            result = removeStringFromArray(result, elt)
        });
        result.push("created_at")
        result.push("updated_at")
    
        return result;
    } catch (error) {
        console.log(error);
        
        return []
    }
}