/*
  Author : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 07/10/2023 20:07:58
*/
import React, { FC, useEffect } from 'react';
import './HeaderDashboard.css';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LOGOUT } from '../../redux/actions/actionTypes';
import { useSelector } from 'react-redux';
import { getAuthState, getCurrentUser, isAdminSelector } from '../../redux/selectors/selectors';
import SwitchTheme from '../SwitchTheme/SwitchTheme';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import { useTranslation } from 'react-i18next';


interface HeaderDashboardProps {

}


const HeaderDashboard: FC<HeaderDashboardProps> = () => {

  const { t } = useTranslation();
  const location = useLocation()
  const dispatch = useDispatch()
  const isAuth = useSelector(getAuthState)
  const user = useSelector(getCurrentUser)
  const isAdmin = useSelector(isAdminSelector)

  useEffect(() => {
    window.scrollTo(0, 0)
    const runLocalData = async () => {

    }
    runLocalData()
  })

  const handleLogout = (event: any) => {
    event.preventDefault()
    dispatch({
      type: LOGOUT,
      payload: null
    })
  }

  if (!location.pathname.startsWith('/dashboard')) {
    return <></>
  }
  if (!isAuth) {
    return <></>
  }
  return (
    <div className="HeaderDashboard shadow">
      <div className="wrapper">
        {/* Sidebar  */}
        <div className="iq-sidebar shadow">
          <div className="iq-sidebar-logo d-flex justify-content-between">
            <Link to="" className="HeaderDashboard-logo">
              <img src="/assets/images/logo.png" className="img-fluid rounded-normal" alt="" />
              <div className="logo-title">
                <span className="text-primary text-uppercase">Ouitube</span>
              </div>
            </Link>
            <div className="iq-menu-bt-sidebar">
              <div className="iq-menu-bt align-self-center">
                <div className="wrapper-menu">
                  <div className="main-circle"><i className="las la-bars" /></div>
                </div>
              </div>
            </div>
          </div>
          <div id="sidebar-scrollbar">
            <nav className="iq-sidebar-menu">
              <ul id="iq-sidebar-toggle" className="iq-menu">
                {/* <li><Link to="/dashboard/" className="iq-waves-effect">
                  <i className="las la-home iq-arrow-left" /><span>Dashboard</span></Link>
                </li>
                <li>
                  <Link to="/dashboard/rating" className="iq-waves-effect"><i className="las la-star-half-alt" /><span>Rating </span></Link>
                </li>
                <li>
                  <Link to="comment" className="iq-waves-effect"><i className="las la-comments" /><span>Comment</span></Link>
                </li> */}

                <li>
                  <a href="#channel" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false"><i className="las la-list-ul" />
                  <span>{t('Channels')}</span>
                  <i className="ri-arrow-right-s-line iq-arrow-right" /></a>
                  <ul id="channel" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                    <li><Link to="/dashboard/channel-list"><i className="las la-eye" />{t('Channel List')}</Link></li>
                    <li><Link to="/dashboard/add/channel"><i className="las la-user-plus" />{t('Add Channel')}</Link></li>
                  </ul>
                </li>
                <li>
                  <a href="#category" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false"><i className="las la-list-ul" />
                  <span>{t('Category')}</span>
                  <i className="ri-arrow-right-s-line iq-arrow-right" /></a>
                  <ul id="category" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                    <li><Link to="/dashboard/category-list"><i className="las la-eye" />{t('Category List')}</Link></li>
                    <li><Link to="/dashboard/add/category"><i className="las la-user-plus" />{t('Add Category')}</Link></li>
                  </ul>
                </li>
                <li>
                  <a href="#movie" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false"><i className="las la-film" /><span>{t('Movie')}</span><i className="ri-arrow-right-s-line iq-arrow-right" /></a>
                  <ul id="movie" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                    <li><Link to="/dashboard/movie-list"><i className="las la-eye" />{t('Movie List')}</Link></li>
                    <li><Link to="/dashboard/add/movie"><i className="las la-user-plus" />{t('Add Movie')}</Link></li>
                    {/* <li><Link to="/dashboard/add-many/movie"><i className="las la-user-plus" />Add Many</Link></li> */}
                  </ul>
                </li>

                <li>
                  <a href="#oauthclient" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false"><i className="las la-film" /><span>{t('Application')}</span><i className="ri-arrow-right-s-line iq-arrow-right" /></a>
                  <ul id="oauthclient" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                    <li><Link to="/dashboard/oauthclient-list"><i className="las la-eye" />{t('Application List')}</Link></li>
                    <li><Link to="/dashboard/add/oauthclient"><i className="las la-user-plus" />{t('Add Application')}</Link></li>
                  </ul>
                </li>

                {
                  isAdmin ?
                    <>
                      <li>
                        <a href="#webinaire" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false"><i className="las la-film" />
                          <span>
                            {t('Webinaire')}
                          </span>
                          <i className="ri-arrow-right-s-line iq-arrow-right" />
                        </a>
                        <ul id="webinaire" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                          <li><Link to="/dashboard/webinaire-list"><i className="las la-eye" />{t('Webinaire List')}</Link></li>
                          <li><Link to="/dashboard/add/webinaire"><i className="las la-user-plus" /> {t('Add Webinaire')}</Link></li>
                          {/* <li><Link to="/dashboard/add-many/movie"><i className="las la-user-plus" />Add Many</Link></li> */}
                        </ul>
                      </li>
                      <li>
                        <a href="#email_template" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false"><i className="las la-film" /><span>{t('Email Template')}</span><i className="ri-arrow-right-s-line iq-arrow-right" /></a>
                        <ul id="email_template" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                          <li><Link to="/dashboard/email_template-list"><i className="las la-eye" />{t('Template List')}</Link></li>
                          <li><Link to="/dashboard/add/email_template"><i className="las la-user-plus" />{t('Add Template')}</Link></li>
                        </ul>
                      </li>
                      <li>
                        <a href="#email_paramater" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false"><i className="las la-film" /><span>{t('Email Setting')}</span><i className="ri-arrow-right-s-line iq-arrow-right" /></a>
                        <ul id="email_paramater" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                          <li><Link to="/dashboard/email_paramater-list"><i className="las la-eye" />{t('Setting List')}</Link></li>
                          <li><Link to="/dashboard/add/email_paramater"><i className="las la-user-plus" />{t('Add Setting')}</Link></li>
                        </ul>
                      </li>
                      <li>
                        <a href="#meta" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false"><i className="las la-film" /><span>{t('Meta Datas')}</span><i className="ri-arrow-right-s-line iq-arrow-right" /></a>
                        <ul id="meta" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                          <li><Link to="/dashboard/meta-list"><i className="las la-eye" />{t('Data List')}</Link></li>
                          <li><Link to="/dashboard/add/meta"><i className="las la-user-plus" />{t('Add Data')}</Link></li>
                        </ul>
                      </li>
                      <li>
                        <a href="#user" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false"><i className="las la-film" /><span>{t('Users')}</span><i className="ri-arrow-right-s-line iq-arrow-right" /></a>
                        <ul id="user" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                          <li><Link to="/dashboard/user-list"><i className="las la-eye" />{t('Users List')}</Link></li>
                          <li><Link to="/dashboard/add/user"><i className="las la-user-plus" />{t('Add User')}</Link></li>
                        </ul>
                      </li>
                      <li>
                        <a href="#emailevent" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
                          <i className="las la-film" />
                          <span>{t('Email Event')}</span>
                          <i className="ri-arrow-right-s-line iq-arrow-right" />
                        </a>
                        <ul id="emailevent" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                          <li><Link to="/dashboard/emailevent-list"><i className="las la-eye" />{t('Event List')}</Link></li>
                          <li><Link to="/dashboard/add/emailevent"><i className="las la-user-plus" />{t('Add Event')}</Link></li>
                        </ul>
                      </li>
                      <li>
                        <a href="#resolution" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
                          <i className="las la-film" />
                          <span>{t('Resolution')}</span>
                          <i className="ri-arrow-right-s-line iq-arrow-right" />
                        </a>
                        <ul id="resolution" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                          <li><Link to="/dashboard/resolution-list"><i className="las la-eye" />{t('Resolution List')}</Link></li>
                          <li><Link to="/dashboard/add/resolution"><i className="las la-user-plus" />{t('Add Resolution')}</Link></li>
                        </ul>
                      </li>
                     
                      <li>
                        <a href="#authstrategy" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
                          <i className="las la-film" />
                          <span>{t('Auth Strategy')}</span>
                          <i className="ri-arrow-right-s-line iq-arrow-right" />
                        </a>
                        <ul id="authstrategy" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                          <li><Link to="/dashboard/authstrategy-list"><i className="las la-eye" />{t('Strategy List')}</Link></li>
                          <li><Link to="/dashboard/add/authstrategy"><i className="las la-user-plus" />{t('Add Strategy')}</Link></li>
                        </ul>
                      </li>
                      <li>
                        <a href="#client" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
                          <i className="las la-film" />
                          <span>{t('Clients')}</span>
                          <i className="ri-arrow-right-s-line iq-arrow-right" />
                        </a>
                        <ul id="client" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                          <li><Link to="/dashboard/client-list"><i className="las la-eye" />{t('Client List')}</Link></li>
                          <li><Link to="/dashboard/add/client"><i className="las la-user-plus" />{t('Add Client')}</Link></li>
                        </ul>
                      </li>
                      <li>
                        <a href="#formule" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
                          <i className="las la-film" />
                          <span>{t('Formules')}</span>
                          <i className="ri-arrow-right-s-line iq-arrow-right" />
                        </a>
                        <ul id="formule" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                          <li><Link to="/dashboard/formule-list"><i className="las la-eye" />{t('Formule List')}</Link></li>
                          <li><Link to="/dashboard/add/formule"><i className="las la-user-plus" />{t('Add Formule')}</Link></li>
                        </ul>
                      </li>
                      <li>
                        <a href="#documentation" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false">
                          <i className="las la-film" />
                          <span>{t('Docs')}</span>
                          <i className="ri-arrow-right-s-line iq-arrow-right" />
                        </a>
                        <ul id="documentation" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                          <li><Link to="/dashboard/documentation-list"><i className="las la-eye" />{t('Docs List')}</Link></li>
                          <li><Link to="/dashboard/add/documentation"><i className="las la-user-plus" />{t('Add Doc')}</Link></li>
                        </ul>
                      </li>
                    </>
                    :
                    null
                }



              </ul>
            </nav>
          </div>
        </div>
        {/* TOP Nav Bar */}
        <div className="iq-top-navbar shadow">
          <div className="iq-navbar-custom">
            <nav className="navbar navbar-expand-lg navbar-light p-0">
              <div className="iq-menu-bt d-flex align-items-center">
                <div className="wrapper-menu">
                  <div className="main-circle"><i className="las la-bars" /></div>
                </div>
                <div className="iq-navbar-logo d-flex justify-content-between">
                  <a href="index" className="HeaderDashboard-logo">
                    <img src="/assets/images/logo.png" className="img-fluid rounded-normal" alt="" />
                    <div className="logo-title">
                      <span className="text-primary text-uppercase">Ouitube</span>
                    </div>
                  </a>
                </div>
              </div>
              <div className="navbar-breadcrumb">
                <h4 className="mb-0 text-dark">Dashboard</h4>

              </div>
              {/* <div className="iq-search-bar ml-auto">
                <form action="#" className="searchbox">
                  <input type="text" className="text search-input" placeholder="Search Here..." />
                  <a className="search-link" href="#"><i className="ri-search-line" /></a>
                </form>
              </div> */}
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-label="Toggle navigation">
                <i className="ri-menu-3-line" />
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto navbar-list">
                  <li className="nav-item nav-icon search-content">
                    <a href="#" className="search-toggle iq-waves-effect text-gray rounded">
                      <i className="ri-search-line" />
                    </a>
                    <form action="#" className="search-box p-0">
                      <input type="text" className="text search-input" placeholder="Type here to search..." />
                      <a className="search-link" href="#"><i className="ri-search-line" /></a>
                    </form>
                  </li>
                  <li className="nav-item nav-icon">
                    <a href="#">
                      <SwitchTheme />
                    </a>
                  </li>
                  <li className="nav-item nav-icon d-flex align-items-center">
                   
                    <LanguageSelector />
                    
                  </li>
                  {/* <li className="nav-item nav-icon">
                    <a href="#" className="search-toggle iq-waves-effect text-gray rounded">
                      <i className="ri-notification-2-fill" />
                      <span className="bg-primary dots" />
                    </a>
                    <div className="iq-sub-dropdown">
                      <div className="iq-card shadow-none m-0">
                        <div className="iq-card-body p-0">
                          <div className="bg-primary p-3">
                            <h5 className="mb-0 text-white">All Notifications<small className="badge  badge-light float-right pt-1">4</small></h5>
                          </div>
                          <a href="#" className="iq-sub-card">
                            <div className="media align-items-center">
                              <div className="">
                                <img className="avatar-40 rounded" src="/assets/images/user/01.jpg" alt="" />
                              </div>
                              <div className="media-body ml-3">
                                <h6 className="mb-0 ">Emma Watson Barry</h6>
                                <small className="float-right font-size-12">Just Now</small>
                                <p className="mb-0">95 MB</p>
                              </div>
                            </div>
                          </a>
                          <a href="#" className="iq-sub-card">
                            <div className="media align-items-center">
                              <div className="">
                                <img className="avatar-40 rounded" src="/assets/images/user/02.jpg" alt="" />
                              </div>
                              <div className="media-body ml-3">
                                <h6 className="mb-0 ">New customer is join</h6>
                                <small className="float-right font-size-12">5 days ago</small>
                                <p className="mb-0">Cyst Barry</p>
                              </div>
                            </div>
                          </a>
                          <a href="#" className="iq-sub-card">
                            <div className="media align-items-center">
                              <div className="">
                                <img className="avatar-40 rounded" src="/assets/images/user/03.jpg" alt="" />
                              </div>
                              <div className="media-body ml-3">
                                <h6 className="mb-0 ">Two customer is left</h6>
                                <small className="float-right font-size-12">2 days ago</small>
                                <p className="mb-0">Cyst Barry</p>
                              </div>
                            </div>
                          </a>
                          <a href="#" className="iq-sub-card">
                            <div className="media align-items-center">
                              <div className="">
                                <img className="avatar-40 rounded" src="/assets/images/user/04.jpg" alt="" />
                              </div>
                              <div className="media-body ml-3">
                                <h6 className="mb-0 ">New Mail from Fenny</h6>
                                <small className="float-right font-size-12">3 days ago</small>
                                <p className="mb-0">Cyst Barry</p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item nav-icon dropdown">
                    <a href="#" className="search-toggle iq-waves-effect text-gray rounded">
                      <i className="fa fa-envelope" aria-hidden="true" />
                      <span className="bg-primary count-mail" />
                    </a>
                    <div className="iq-sub-dropdown">
                      <div className="iq-card shadow-none m-0">
                        <div className="iq-card-body p-0 ">
                          <div className="bg-primary p-3">
                            <h5 className="mb-0 text-white">All Messages<small className="badge  badge-light float-right pt-1">5</small></h5>
                          </div>
                          <a href="#" className="iq-sub-card">
                            <div className="media align-items-center">
                              <div className="">
                                <img className="avatar-40 rounded" src="/assets/images/user/01.jpg" alt="" />
                              </div>
                              <div className="media-body ml-3">
                                <h6 className="mb-0 ">Barry Emma Watson</h6>
                                <small className="float-left font-size-12">13 Jun</small>
                              </div>
                            </div>
                          </a>
                          <a href="#" className="iq-sub-card">
                            <div className="media align-items-center">
                              <div className="">
                                <img className="avatar-40 rounded" src="/assets/images/user/02.jpg" alt="" />
                              </div>
                              <div className="media-body ml-3">
                                <h6 className="mb-0 ">Lorem Ipsum Watson</h6>
                                <small className="float-left font-size-12">20 Apr</small>
                              </div>
                            </div>
                          </a>
                          <a href="#" className="iq-sub-card">
                            <div className="media align-items-center">
                              <div className="">
                                <img className="avatar-40 rounded" src="/assets/images/user/03.jpg" alt="" />
                              </div>
                              <div className="media-body ml-3">
                                <h6 className="mb-0 ">Why do we use it?</h6>
                                <small className="float-left font-size-12">30 Jun</small>
                              </div>
                            </div>
                          </a>
                          <a href="#" className="iq-sub-card">
                            <div className="media align-items-center">
                              <div className="">
                                <img className="avatar-40 rounded" src="/assets/images/user/04.jpg" alt="" />
                              </div>
                              <div className="media-body ml-3">
                                <h6 className="mb-0 ">Variations Passages</h6>
                                <small className="float-left font-size-12">12 Sep</small>
                              </div>
                            </div>
                          </a>
                          <a href="#" className="iq-sub-card">
                            <div className="media align-items-center">
                              <div className="">
                                <img className="avatar-40 rounded" src="/assets/images/user/05.jpg" alt="" />
                              </div>
                              <div className="media-body ml-3">
                                <h6 className="mb-0 ">Lorem Ipsum generators</h6>
                                <small className="float-left font-size-12">5 Dec</small>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </li> */}
                  <li className="line-height pt-3">
                    <a href="#" className="search-toggle iq-waves-effect d-flex align-items-center">
                      <img src="/assets/images/user/1.jpg" className="img-fluid avatar-50 rounded-circle mr-3" alt="user" />
                    </a>
                    <div className="iq-sub-dropdown iq-user-dropdown">
                      <div className="iq-card shadow-none m-0">
                        <div className="iq-card-body p-0 ">
                          <div className="bg-primary p-3">
                            <h5 className="mb-0 text-white line-height">Hello {user.fullName}</h5>
                            {/* <span className="text-white font-size-12">Available</span> */}
                          </div>
                          <Link to="/dashboard/profile" className="iq-sub-card iq-bg-primary-hover">
                            <div className="media align-items-center">
                              <div className="rounded iq-card-icon iq-bg-primary">
                                <i className="ri-file-user-line" />
                              </div>
                              <div className="media-body ml-3">
                                <h6 className="mb-0 ">My Profile</h6>
                                <p className="mb-0 font-size-12">View personal profile details.</p>
                              </div>
                            </div>
                          </Link>
                          <a href="profile-edit" className="iq-sub-card iq-bg-primary-hover">
                            <div className="media align-items-center">
                              <div className="rounded iq-card-icon iq-bg-primary">
                                <i className="ri-profile-line" />
                              </div>
                              <div className="media-body ml-3">
                                <h6 className="mb-0 ">Edit Profile</h6>
                                <p className="mb-0 font-size-12">Modify your personal details.</p>
                              </div>
                            </div>
                          </a>
                          <a href="account-setting" className="iq-sub-card iq-bg-primary-hover">
                            <div className="media align-items-center">
                              <div className="rounded iq-card-icon iq-bg-primary">
                                <i className="ri-account-box-line" />
                              </div>
                              <div className="media-body ml-3">
                                <h6 className="mb-0 ">Account settings</h6>
                                <p className="mb-0 font-size-12">Manage your account parameters.</p>
                              </div>
                            </div>
                          </a>
                          <Link to="/documentation" className="iq-sub-card iq-bg-primary-hover">
                            <div className="media align-items-center">
                              <div className="rounded iq-card-icon iq-bg-primary">
                                <i className="ri-account-box-line" />
                              </div>
                              <div className="media-body ml-3">
                                <h6 className="mb-0 ">API Ouitube</h6>
                                <p className="mb-0 font-size-12">Manage your account parameters.</p>
                              </div>
                            </div>
                          </Link>
                          <a href="privacy-setting" className="iq-sub-card iq-bg-primary-hover">
                            <div className="media align-items-center">
                              <div className="rounded iq-card-icon iq-bg-primary">
                                <i className="ri-lock-line" />
                              </div>
                              <div className="media-body ml-3">
                                <h6 className="mb-0 ">Privacy Settings</h6>
                                <p className="mb-0 font-size-12">Control your privacy parameters.</p>
                              </div>
                            </div>
                          </a>
                          <div className="d-inline-block w-100 text-center p-3">
                            <Link to={"/"} className="bg-primary iq-sign-btn" onClick={handleLogout} role="button">Sign out<i className="ri-login-box-line ml-2" /></Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderDashboard;