import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Import des composants
import { About } from './pages/About';
// import { Home } from './pages/Home';
import SignIn from './components/SignIn/SignIn';
import SignUp from './components/SignUp/SignUp';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import DataList from './pages/Data/DataList/DataList';
import HeaderDashboard from './components/HeaderDashboard/HeaderDashboard';
import Rating from './components/Rating/Rating';
import Comment from './components/Comment/Comment';
import User from './components/User/User';
import PrivateRoute from './guard/PrivateRoute/PrivateRoute';
import NotificationComponent from './components/NotificationComponent/NotificationComponent';
import ManageData from './pages/Data/ManageData/ManageData';
import ErrorPage from './components/ErrorPage/ErrorPage';
import Profile from './components/Profile/Profile';
import VerifyCode from './components/VerifyCode/VerifyCode';
import VerifyEmail from './components/VerifyEmail/VerifyEmail';
import ResetPassword from './components/ResetPassword/ResetPassword';
import LivePage from './components/LivePage/LivePage';
import Watch from './components/Watch/Watch';
import ChoosePlan from './components/ChoosePlan/ChoosePlan';
import { getItem } from './helpers/localsorage.service';
import Home from './components/Home/Home';
import Documentation from './components/Documentation/Documentation';
import AuthSuccess from './components/AuthSuccess/AuthSuccess';

const App: React.FC = () => {

  const isOn = getItem("theme") === "dark"

  if(!isOn){
    document.body.classList.add("light")
  }else{
    document.body.classList.remove("light")
  }


  
  return (
    <BrowserRouter>
      {/* En-tête de la page */}
      <HeaderDashboard />
      <Routes>
        {/* Routes d'authentification */}
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/verification/:partial_token" element={<VerifyCode />} />
        <Route path="/verification-email/:partial_token" element={<VerifyEmail />} />
        <Route path="/reset-password/:reset_password_token" element={<ResetPassword />} />
        <Route path="/reset-email/:partial_token" element={<VerifyCode />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/auth/success" element={<AuthSuccess />} />

        {/* Page d'accueil */}
        <Route path="/" element={<Home />} />
        <Route path="/choose-plan" element={<ChoosePlan />} />
        <Route path="/pricing" element={<ChoosePlan />} />
        <Route path="/documentation" element={<Documentation />} />
        <Route path="/documentation/:documentSlug/:sectionSlug" element={<Documentation />} />

        {/* Routes du tableau de bord (avec authentification requise) */}
        <Route path="/dashboard/watch" element={<PrivateRoute><Watch /></PrivateRoute>} />
        <Route path="/dashboard/add/:entity" element={<PrivateRoute><ManageData /></PrivateRoute>} />
        <Route path="/dashboard/update/:entity/:entityId" element={<PrivateRoute><ManageData /></PrivateRoute>} />
        <Route path="/dashboard/view/:entity/:entityId" element={<PrivateRoute><ManageData /></PrivateRoute>} />
        <Route path="/dashboard/:entityName-list" element={<PrivateRoute><DataList /></PrivateRoute>} />
        <Route path="/dashboard/rating" element={<PrivateRoute><Rating /></PrivateRoute>} />
        <Route path="/dashboard/comment" element={<PrivateRoute><Comment /></PrivateRoute>} />
        <Route path="/dashboard/user" element={<PrivateRoute><User /></PrivateRoute>} />
        <Route path="/dashboard/about" element={<PrivateRoute><About /></PrivateRoute>} />
        <Route path="/dashboard/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
        <Route path="/dashboard/live" element={<PrivateRoute><LivePage /></PrivateRoute>} />
        <Route path="/dashboard/live/:webinaireId" element={<PrivateRoute><LivePage /></PrivateRoute>} />

        {/* Page d'erreur générique et route inconnue */}
        <Route path="error" element={<ErrorPage />} />
        <Route path="**" element={<ErrorPage />} />
      </Routes>

      {/* Composant de notification */}
      <NotificationComponent />
    </BrowserRouter>
  );
}

export default App;
