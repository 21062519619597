/*
  Author : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 16/10/2023 17:32:47
*/
import React, { FC, useEffect, useState } from 'react';
import './VideoList.css';
import { getDatasByPage } from '../../api/api-entity';
import { Video } from '../../models/Video';
import Loading from '../Loading/Loading';
import VideoItem from '../VideoItem/VideoItem';
import { useSelector } from 'react-redux';
import { getCurrentVideo } from '../../redux/selectors/selectors';
import { Link, useLocation } from 'react-router-dom';


interface VideoListProps {

}


const VideoList: FC<VideoListProps> = () => {


  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [data, setData] = useState<any>(null)

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page')
  const storageCurrent = useSelector(getCurrentVideo)

  useEffect(() => {
    const runLocalData = async () => {
      const newPage = page ? parseInt(page) : 1
      const newData = await getDatasByPage("video", newPage, 10)
      if (newData.isSuccess) {
        if (storageCurrent) {
          const results = newData.results.filter((r: any) => r.uniqueCode !== storageCurrent?.uniqueCode)
          if (newData) {
            newData.results = [...newData.results, ...results]
          } else {
            newData.results = results
          }
        }
        setData(newData)
        setIsLoading(false)
      }
    }
    runLocalData()
  }, [storageCurrent, page])

  return (
    <div className="VideoList">
      {
        isLoading || !data ?
          <Loading />
          :

          <div className="iq-card iq-card iq-card-block iq-card-stretch iq-card-height">
            <div className="iq-card-header">
              <div className="iq-header-title">
                <h4 className="card-title text-center">Videos List</h4>
              </div>
            </div>
            <div className="iq-card-body pb-0">
              {
                data.results.map((video: Video) => {
                  return <VideoItem key={video._id} video={video} />
                })
              }
              {data.next && (

                <Link to={"?page=" + data.next}>
                  <button className="page-link">
                    Next Page
                  </button>
                </Link>
              )}
            </div>
          </div>
      }
    </div>
  );
}

export default VideoList;