/*
  Author : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 24/10/2023 14:40:16
*/
import React, { useState } from 'react';
import './SubscriptionSwitch.css'
import { useTranslation } from 'react-i18next';


interface SubscriptionSwitchProps {
  onToggle: (value: boolean) => void
}



const SubscriptionSwitch: React.FC<SubscriptionSwitchProps> = ({ onToggle }) => {
  const [isAnnually, setIsAnnually] = useState(true);
  const { t } = useTranslation();

  const handleToggle = () => {
    setIsAnnually(!isAnnually);
    onToggle(!isAnnually);
  };

  return (
    <div className='d-flex justify-content-center p-2 rounded'>
      <div className="ouitube-plan">
          <button className={isAnnually ? 'current-item border shadow' : 'border shadow'} onClick={handleToggle}>{t('annually')}</button>
          <button className={isAnnually ? 'border shadow' : 'current-item border shadow'} onClick={handleToggle}>{t('monthly')}</button>
      </div>
      {/* <div onClick={handleToggle}>
      <FontAwesomeIcon icon={isAnnually ? faToggleOn : faToggleOff} />
      </div> */}
    </div>
  );
};

export default SubscriptionSwitch;
