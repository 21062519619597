/*
  Author : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 07/10/2023 21:19:52
*/
import React, { FC, useEffect, useState } from 'react';
import './ManageData.css';
import { Link, useLocation } from 'react-router-dom';
import { analyzeRoute } from '../../../helpers/utils';
import { getDatasById } from '../../../api/api-entity';
import ManageEntityForm from '../../../components/ManageEntityForm/ManageEntityForm';



interface ManageDataProps {
}


const ManageData: FC<ManageDataProps> = () => {

  const location = useLocation()
  const [routeData, setRouteData] = useState<any>(analyzeRoute(location.pathname)) 
  const [entityData, setEntityData] = useState<any>(null) 
  const [isLoading, setIsLoading] = useState<any>(true) 



  useEffect(() => {
    window.scrollTo(0, 0)
    const runLocalData = async () => {
      const routeValues = analyzeRoute(location.pathname)
      console.log({routeValues});
      
      setRouteData(routeValues)
      if(routeValues?.action && ["Update","View"].includes(routeValues?.action) && routeValues.entity && routeValues.id){
        const newData = await getDatasById(routeValues.entity,routeValues.id)
        if(newData.isSuccess && newData.result){
          setEntityData(newData.result)
          setIsLoading(false)
        }
      }
    }
    runLocalData()
  },[isLoading, location.pathname])

  return (
    <div className="ManageData">
      <div id="content-page" className="content-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">{routeData?.action} {routeData?.entity}</h4>
                  </div>
                  <div className="iq-card-header-toolbar d-flex align-items-center">
                    <Link to={"/dashboard/"+routeData.entity.toLowerCase()+"-list"} className="btn btn-primary">{routeData.entity} List</Link>
                  </div>
                </div>
                <div className="iq-card-body">
                    <ManageEntityForm 
                    routeData={routeData}
                    entityData={entityData}
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default ManageData;