/*
  Author : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 24/10/2023 19:00:29
*/
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import TextEditor from '../TextEditor/TextEditor';

interface SectionCRUDProps {
  datas: any[];
  handleChange: (newData: any) => void
}

const SectionCRUD: React.FC<SectionCRUDProps> = ({ datas, handleChange }) => {
  const [sections, setSections] = useState<any[]>(datas); // Pour stocker les sections
  const [showModal, setShowModal] = useState(false);
  const [section, setSection] = useState<any>({ index: -1, title: '', content: '', slug: '' , createdAt: '', updatedAt: '' });

  useEffect(() => {
    setSections(datas)
  }, [datas.length])
  // Afficher le modal pour créer/éditer une section
  const handleShowModal = (data?: any) => {
    setShowModal(true);
    if (data) {
      setSection(data);
    } else {
      setSection({ index: -1, title: '', content: '', slug: '' , createdAt: '', updatedAt: '' });
    }
  };

  // Fermer le modal
  const handleCloseModal = () => {
    setShowModal(false);
    setSection({ index: -1, title: '', content: '', slug: '' , createdAt: '', updatedAt: '' });
  };

  // Fonction pour créer un slug à partir du titre
  const slugify = (title: string) => {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9 -]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '');
  };
  // Vérifier si le slug est unique
  const isSlugUnique = (proposedSlug: string) => {
    return sections.every((s) => s.slug !== proposedSlug);
  };
  // Fonction pour créer un slug à partir du titre
  const generateUniqueSlug = (title: string) => {
    const baseSlug = slugify(title);
    let slug = baseSlug;
    let counter = 2;
    
    while (!isSlugUnique(slug)) {
      slug = `${baseSlug}-${counter}`;
      counter++;
    }

    return slug;
  };

  // Enregistrer la section
  const saveSection = () => {
    // Insérez ici la logique pour enregistrer la section dans votre backend
    // Vous pouvez utiliser une API pour cela
    // Une fois la section enregistrée avec succès, mettez à jour le state et fermez le modal
    const updatedSection = { ...section };
    updatedSection.slug = generateUniqueSlug(section.title);
   
    let updatedSections 

    if (section?.index === -1) {
      // Il s'agit d'une nouvelle section, ajoutez-la au tableau
      updatedSections = [...sections, updatedSection]
      setSections([...sections, updatedSection]);
    } else {
      // Il s'agit d'une section existante, mettez à jour ses propriétés
      updatedSections = [...sections];
      updatedSections[section.index] = updatedSection;
      setSections(updatedSections);
    }
    handleChange(updatedSections)
    handleCloseModal();
  };

  // Supprimer une section
  const deleteSection = (index: number) => {
    // Insérez ici la logique pour supprimer la section de votre backend
    // Une fois la section supprimée avec succès, mettez à jour le state
    const updatedSections = [...sections];
    updatedSections.splice(index, 1);
    setSections(updatedSections);
    handleChange(updatedSections)
  };

  



  return (
    <div>
      <Button variant="primary" onClick={() => handleShowModal()}>Ajouter une section</Button>

      <Modal show={showModal} size='lg' scrollable onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Créer/Éditer une section</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Entrez le titre"
                value={section.title}
                onChange={(e) => setSection({ ...section, title: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="content">
              <Form.Label>Content</Form.Label>
              <TextEditor
                value={section.content}
                handleChange={(value: string) => setSection({ ...section, content: value })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Annuler
          </Button>
          <Button variant="primary" onClick={saveSection}>
            Enregistrer
          </Button>
        </Modal.Footer>
      </Modal>

      <ul>
        {sections.map((currentSection, index) => (
          <li key={index}>
            <strong>{currentSection.title}</strong>
            <Button variant="warning" onClick={() => handleShowModal({ index, ...currentSection })}>
              Éditer
            </Button>

            <Button variant="danger" onClick={() => deleteSection(index)}>Supprimer</Button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SectionCRUD;
