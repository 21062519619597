/*
  Author : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 15/10/2023 14:04:06
*/
import React, { FC, useEffect, useState } from 'react';
import './LivePage.css';
import { useParams } from 'react-router-dom';
import { getDatas } from '../../api/api-entity';
import { Webinaire } from '../../models/Webinaire';
import { formattedDate } from '../../helpers/utils';


interface LivePageProps {

}

const LivePage: FC<LivePageProps> = () => {

  const { webinaireId } = useParams()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [webinaires, setWebinaires] = useState<Webinaire[]>([])

  useEffect(() => {
    window.scrollTo(0, 0)
    const runLocalData = async () => {
      if (!webinaireId) {
        setIsLoading(false)
        const data = await getDatas("webinaire")
        if (data.isSuccess) {
          setWebinaires(data.results)
        }
      }
    }
    runLocalData()
  }, [isLoading])

  return (
    <div className="LivePage">
      <h1>
        Live Lists
      </h1>
      <ul className='list-group'>
        {
          webinaires.map((webinaire) => {
            return <li key={webinaire._id} className='list-item'>
              <a href="">
                {webinaire.title} <br/>
                Durée {formattedDate(webinaire.started_at)} <br/>

              </a>
              <button>S'inscrire</button>
            </li>
          })
        }

      </ul>
    </div>
  );
}

export default LivePage;