/*
  Author : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 07/10/2023 21:56:01
*/
import React, { FC, useEffect } from 'react';
import './Rating.css';


interface RatingProps {
 
}


const Rating : FC<RatingProps> = () =>{



    useEffect(() => {
      window.scrollTo(0,0)
      const runLocalData = async () => {

      }
      runLocalData()
    })

  return (
      <div className="Rating">
          Rating Component
      </div>
  );
}

export default Rating;