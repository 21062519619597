/*
  Author : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 16/10/2023 14:05:40
*/
import React, { FC, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import './ImagePreviewComponent.css';


interface ImagePreviewComponentProps {
 imageUrls: string[],
 close: ()=>void
}


const ImagePreviewComponent : FC<ImagePreviewComponentProps> = ({imageUrls, close}) =>{

  const [currentImage, setCurrentImage] = useState(0);
  const [showModal, setShowModal] = useState(false);

  useEffect(()=>{
    setShowModal(true);
  })
 

  const handleClose = () => {
    setShowModal(false);
    close()
  };

  const handleClick = () => {
    setCurrentImage((currentImage + 1) % imageUrls.length);
  };



  return (
    <div>

      <Modal show={showModal} centered size="lg"  onHide={handleClose}>
        <Modal.Header>
          {/* <Modal.Title>Image Preview</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <img 
            width="100%" 
            src={imageUrls[currentImage]} 
            alt="Preview" 
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
          {
            imageUrls.length > 1 ?
            <Button variant="secondary" onClick={handleClick}>
              Next Image
            </Button>
            :
            null
          }
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ImagePreviewComponent;