/*
  Author : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 07/10/2023 21:19:52
*/
import React, { FC, useEffect, useState } from 'react';
import './DataList.css';
import { deleteData, getDatasByPage } from '../../../api/api-entity';
import { capitalizeFirstLetter, generateId } from '../../../helpers/utils';
import { Link, Navigate, useLocation, useParams } from 'react-router-dom';

import { ADD_NOTIFICATION } from '../../../redux/actions/actionTypes';
import { useDispatch } from 'react-redux';
import DataTable from '../DataTable/DataTable';
import Loading from '../../../components/Loading/Loading';
import PageLimit from '../../../components/PageLimit/PageLimit';
import { getItem } from '../../../helpers/localsorage.service';
import Pagination from '../../../components/Pagination/Pagination';
import ModalConfirm from '../../../components/ModalConfirm/ModalConfirm';


interface DataListProps {

}


const DataList: FC<DataListProps> = () => {


  const dispatch = useDispatch()
  const { entityName } = useParams()
  const location = useLocation()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [data, setData] = useState<any>(null)
  const [errorPage, setErrorPage] = useState<boolean>(false)
  const [pageLimit, setPageLimit] = useState<any>(getItem('pageLimit') || 5)
  const [pageNumber, setPageNumber] = useState<any>(getItem('pageNumber') || 1)
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentData, setCurrentData] = useState<any>(null);

  useEffect(() => {
    window.scrollTo(0, 0)
    setData([])
    const runLocalData = async () => {
      setIsLoading(true)
      if (entityName) {
        const result = await getDatasByPage(entityName, pageNumber, pageLimit)
        if (result.isSuccess) {
          setData(result)
          setIsLoading(false)
        } else {
          setErrorPage(true)
        }
      }
    }
    runLocalData()
  }, [entityName, location.pathname, pageNumber, pageLimit])


  const handleDelete = (event: any, value: any) => {
    event.preventDefault()
    console.log(data.results);
    setShowModal(true);
    setCurrentData(value)
    return; 
    
  }
  const handleConfirmDelete = () =>{
    if(currentData){
      let newData : any = Object.assign({}, data)
  
      newData.results = data.results?.filter((d: any) => d._id !== currentData._id)
  
      setData(newData)
      if (entityName) {
        deleteData(entityName, currentData._id)
      }
      dispatch({
        type: ADD_NOTIFICATION,
        payload: {
          _id: generateId(),
          message: "Delete succesfuly !",
          status: "success",
          timeout: 2000
        }
      })
      setShowModal(false);
      setCurrentData(null)
    }
  }

  const handleSetLimit = (limit: number) => {
    console.log({ limit });

    setPageLimit(limit)
  }
  const goToPage = (page: number) => {
    setPageNumber(page)
  }

  if (errorPage) {
    return <Navigate to={"/error"} />
  }


  return (
    <div className="DataList">
      <ModalConfirm
        name={currentData?.name || ""}
        show={showModal}
        onHide={()=>setShowModal(false)}
        onConfirm={handleConfirmDelete}
      />
      {
        isLoading || !entityName ?
          <Loading />
          :
          <div id="content-page" className="content-page">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12">
                  <div className="iq-card">
                    <div className="iq-card-header d-flex align-items-center justify-content-between gap-1">
                      <div className="iq-header-title">
                        <h4 className="card-title">{entityName && capitalizeFirstLetter(entityName)} Lists</h4>
                      </div>
                      {
                        data?.results?.length ?
                          <div className="d-flex align-items-center gap-2">

                            <PageLimit
                              handleSetLimit={handleSetLimit}
                            />

                            <Pagination
                              totalDataCount={data.allCount}
                              itemsPerPage={pageLimit}
                              currentPage={data.current}
                              nextPage={data.next}
                              previousPage={data.previous}
                              goToPage={goToPage}
                            />

                            <Link to={"/dashboard/add/" + entityName} className="btn btn-primary">
                              Add {entityName && capitalizeFirstLetter(entityName)}
                            </Link>
                          </div>
                        :
                        null
                      }
                    </div>
                    {
                      data?.results?.length ?
                      <>
                        <DataTable
                          entityName={entityName}
                          data={data}
                          handleDelete={handleDelete}
                        />
                        <div className="d-flex iq-card-footer">
                          <Pagination
                            totalDataCount={data.allCount}
                            itemsPerPage={pageLimit}
                            currentPage={data.current}
                            nextPage={data.next}
                            previousPage={data.previous}
                            goToPage={goToPage}
                          />
                          <PageLimit
                            handleSetLimit={handleSetLimit}
                          />
                        </div>
                      </>
                      :
                      <>
                      <div className="d-flex flex-column p-2 gap-2 align-items-center">
                        <div>Sorry, no data available</div> 
                        <Link to={"/dashboard/add/" + entityName} className="btn btn-primary">
                              Add {entityName && capitalizeFirstLetter(entityName)}
                            </Link>
                      </div>
                      </>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

      }

    </div>
  );
}

export default DataList;