/*
  Author : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 13/10/2023 17:59:16
*/
import React, { FC, useEffect, useState } from 'react';
import './SwitchTheme.css';
import OnOff from '../OnOff/OnOff';
import { getItem, setItem } from '../../helpers/localsorage.service';

interface SwitchThemeProps {
 
}

const SwitchTheme : FC<SwitchThemeProps> = () =>{

  const [isOn, setIsOn] = useState<boolean>(getItem("theme") == "dark")


  const updateTheme  = () =>{
    if(!isOn){
      document.body.classList.add("light")
    }else{
      document.body.classList.remove("light")
    }
    setItem("theme", isOn ? "dark" : "light")
  }

  const handleSwitchTheme = () =>{
    setIsOn(!isOn)
    updateTheme()
  }
    useEffect(() => {
      window.scrollTo(0,0)
      const runLocalData = async () => {
        updateTheme()
      }
      runLocalData()
    })

    

    


  return (
      <div className="SwitchTheme" onClick={handleSwitchTheme}>
          <OnOff
            on={isOn}
          />
      </div>
  );
}

export default SwitchTheme;