/*
  Author : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 18/10/2023 09:07:09
*/
import React from 'react';

interface PaginationProps {
  currentPage: number;
  nextPage: number | null;
  previousPage: number | null;
  goToPage: (pageNumber: number) => void;
  totalDataCount: number; // Nouveau paramètre pour le nombre total de données
  itemsPerPage: number; // Nouveau paramètre pour le nombre d'éléments par page
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, nextPage, previousPage, goToPage, totalDataCount, itemsPerPage }) => {

  const totalPages = Math.ceil(totalDataCount / itemsPerPage);

  const range = 2; // Nombre de pages à afficher de chaque côté de la page actuelle

  const pages = [];
  for (let i = Math.max(1, currentPage - range); i <= Math.min(currentPage + range, totalPages); i++) {
    pages.push(i);
  }

  const pagesToShow: number[] = [];

  if (previousPage !== null) {
    pagesToShow.push(previousPage);
  }

  pagesToShow.push(currentPage);

  if (nextPage !== null) {
    pagesToShow.push(nextPage);
  }

  return (
    <div className='d-flex align-items-center gap-1'>
      {currentPage}  / {totalPages} 
      <nav aria-label="Page navigation d-flex">
        <ul className="pagination m-0">
      

          {previousPage !== null && (
            <li className="page-item">
              <button className="page-link" onClick={() => goToPage(previousPage)}>
                Précédent
              </button>
            </li>
          )}

          {currentPage > 3 && (
            <li className="page-item">
              <button className="page-link" onClick={() => goToPage(1)}>
                {1}
              </button>
            </li>

          )}
          {totalPages > 6 && (
            <li className="page-item">
              <button className="page-link" onClick={() => goToPage(currentPage - 1)}>
                {"<<"}
              </button>
            </li>

          )}

          {pagesToShow.map((page) => (
            <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
              <button className="page-link" onClick={() => goToPage(page)}>
                {page}
              </button>
            </li>
          ))}

          {totalPages > 6 && (
            <li className="page-item">
              <button className="page-link" onClick={() => goToPage(currentPage + 1)}>
                {">>"}
              </button>
            </li>

          )}

          {
            totalPages > 4 && currentPage!== totalPages && (
              <li className="page-item">
                <button className="page-link" onClick={() => goToPage(totalPages)}>
                  {totalPages}
                </button>
              </li>
            )
          }

          {nextPage !== null && (
            <li className="page-item">
              <button className="page-link" onClick={() => goToPage(nextPage)}>
                Suivant
              </button>
            </li>
          )}

        </ul>

      </nav>
    </div>
  );
}

export default Pagination;
