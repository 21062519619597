/*
  Author : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 11/10/2023 08:54:44
*/
import React, { FC, useEffect } from 'react';
import './ModalConfirm.css';
import { Modal, Button } from 'react-bootstrap';


interface ModalConfirmProps {
  name: string;
  show: boolean;
  onHide: () => void;
  onConfirm: () => void;
}


const ModalConfirm: FC<ModalConfirmProps> = ({ name, show, onHide, onConfirm  }) => {



  useEffect(() => {
    window.scrollTo(0, 0)
    const runLocalData = async () => {
    }
    runLocalData()
  })

  return (
    <Modal show={show} onHide={onHide} centered scrollable>
    <Modal.Header closeButton>
      <Modal.Title>Confirm Deletion</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      Are you sure you want to delete this item : {name}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onHide}>
        Cancel
      </Button>
      <Button variant="danger" onClick={onConfirm}>
        Confirm
      </Button>
    </Modal.Footer>
  </Modal>
  );
}

export default ModalConfirm;