/*
  Author : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 14/10/2023 14:34:34
*/
import React  from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface TextEditorProps {
  value: string;
  handleChange: (e: any)=>void
}

const TextEditor: React.FC<TextEditorProps> = ({ value, handleChange }) => {

  // const [text, setText] = useState(value);

  // const handleChange = (newValue: string) => {
  //   setText(newValue);
  // };

  return (
    <div>
      <ReactQuill
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

export default TextEditor;






