/*
  Author : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 11/10/2023 19:00:49
*/
import React, { FC, useEffect } from 'react';
import './ProgressBar.css';


interface ProgressBarProps {
  progress: number
}


const ProgressBar: FC<ProgressBarProps> = ({progress}) => {



  useEffect(() => {
    window.scrollTo(0, 0)
    const runLocalData = async () => {

    }
    runLocalData()
  },[progress])

  return (
    <div className="ProgressBar">
      <div className="progress-container">
        <div style={{ width: progress + "%" }} className="progress-bar" id="progressBar">
          {progress.toFixed(0) + "%"}
        </div>
      </div>
    </div>
  );
}

export default ProgressBar;