import React, { useEffect, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

interface Option {
  id: number;
  name: string;
}
interface AddOptionsProps {
  datas: Option[];
  handleChange: (newData: any) =>void
}

const AddOptions: React.FC<AddOptionsProps> = ({datas, handleChange}) => {
  
  const [options, setOptions] = useState<Option[]>(datas);
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [optionName, setOptionName] = useState('');

  useEffect(()=>{
    setOptions(datas)
  },[datas.length])

  const addOption = () => {
    const newOption: Option = {
      id: options.length + 1,
      name: optionName,
    };
    let newData = [...options, newOption]
    setOptions(newData);
    handleChange(newData)
    setOptionName('');
    setShowModal(false);
  };

  const editOption = () => {
    if (selectedOption) {
      const updatedOptions = options.map((option) =>
        option.id === selectedOption.id ? { ...option, name: optionName } : option
      );
      setOptions(updatedOptions);
      handleChange(updatedOptions)
      setOptionName('');
      setSelectedOption(null);
      setShowModal(false);
    }
  };

  const deleteOption = (id: number) => {
    const updatedOptions = options.filter((option) => option.id !== id);
    setOptions(updatedOptions);
    handleChange(updatedOptions);
    
  };

  return (
    <div className="">
      <h4>Options</h4>
      <ul className='list-group'>
        {options.map((option) => (
          <li key={option.id} className='m-1 d-flex gap-2 justify-content-between'>
            {option.name}{' '}
            <div>

            <Button
              variant="danger"
              size="sm"
              className='mr-1'
              onClick={() => deleteOption(option.id)}
            >
              Supprimer
            </Button>
            <Button
              variant="info"
              size="sm"
              onClick={() => {
                setSelectedOption(option);
                setOptionName(option.name);
                setShowModal(true);
              }}
            >
              Modifier
            </Button>
            </div>
          </li>
        ))}
      </ul>
      <Button variant="primary" onClick={() => setShowModal(true)}>
        Ajouter une option
      </Button>

      <Modal show={showModal} centered onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedOption ? 'Modifier l' : 'Ajouter une nouvelle'} option
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="optionName">
            <Form.Label>Nom de l'option</Form.Label>
            <Form.Control
              type="text"
              value={optionName}
              onChange={(e) => setOptionName(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Fermer
          </Button>
          {selectedOption ? (
            <Button variant="primary" onClick={editOption}>
              Modifier
            </Button>
          ) : (
            <Button variant="primary" onClick={addOption}>
              Ajouter
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddOptions;
