/*
  Author : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 18/10/2023 09:53:58
*/
import React, { FC, useEffect, useState } from 'react';
import './PageLimit.css';
import { getItem, setItem } from '../../helpers/localsorage.service';


interface PageLimitProps {
  handleSetLimit: (limit: number) => void
}


const PageLimit: FC<PageLimitProps> = ({handleSetLimit}) => {

  const [pageLimit, setPageLimit] = useState<any>( getItem('pageLimit')|| 5)
  useEffect(() => {
    window.scrollTo(0, 0)
    const runLocalData = async () => {
      handleSetLimit(pageLimit)
    }
    runLocalData()
  })

  const handleChange = (event: any) => {
    const {value} = event.target 
    setItem('pageLimit', parseInt(value))
    setPageLimit(parseInt(value))
  }

  return (
    <div className="PageLimit">
      <select name="pageLimit" onClick={handleChange}  className='form-control'>
        <option selected={pageLimit === 1} value="1">1</option>
        <option selected={pageLimit === 5} value="5">5</option>
        <option selected={pageLimit === 10} value="10">10</option>
        <option selected={pageLimit === 20} value="20">20</option>
        <option selected={pageLimit === 50} value="50">50</option>
        <option selected={pageLimit === 100} value="100">100</option>
      </select>
    </div>
  );
}

export default PageLimit;