/*
  Author : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 10/10/2023 09:25:50
*/

import React, { FC, useEffect, useState } from 'react';
import './ManageEntityForm.css';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { capitalizeFirstLetter, generateFileUrl, generateId, viewDate } from '../../helpers/utils';
import { addData, addDataWithFile, getVideoLink, regenerateApplication, updateData, updateDataWithFile } from '../../api/api-entity';
import { ADD_NOTIFICATION } from '../../redux/actions/actionTypes';
import { getFields } from '../../helpers/manageRoutes';
import ProgressBar from '../ProgressBar/ProgressBar';
import KeyInput from '../KeyInput/KeyInput';
import TextEditor from '../TextEditor/TextEditor';
import SelectEmailTemplate from '../SelectData/SelectData';
import AddOrigin from '../AddOrigin/AddOrigin';
import { Origin } from '../../models/Origin';
import AddOptions from '../AddOptions/AddOptions';
import SectionCRUD from '../SectionCRUD/SectionCRUD';
import { OuitubePlayer } from 'ouitube-player';


interface ManageEntityFormProps {
  routeData: any;
  entityData?: any;
}


const ManageEntityForm: FC<ManageEntityFormProps> = ({ routeData, entityData }) => {

  const location = useLocation()
  const dispatch = useDispatch()
  const [fileSelected, setFileSelected] = useState<File | null>(null)
  const [fileLink, setFileLink] = useState<string | null>(null)
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false)
  const [formValue, setFormValue] = useState<any>(entityData || {})
  const [progress, setProgress] = useState<number>(0)
  const [isUploding, setIsUploading] = useState<boolean>(false)
  const [fields, setFields] = useState<any>([])
  const [redirect, setRedirect] = useState<any>(null)



  useEffect(() => {
    console.log({ routeData, entityData });
    window.scrollTo(0, 0)
    const runLocalData = async () => {
      const newFiels = getFields(routeData.entity)
      console.log({ newFiels, entity: routeData.entity });
      setFormValue(entityData || {})
      setFields(newFiels)
      if (entityData?.videoUri) {
        console.log({entityData});
        const videoLink = getVideoLink(entityData.uniqueCode)
        setFileLink(videoLink)
      }
    }
    runLocalData()
  }, [entityData, routeData, location.pathname])

  const handleSelectFile = async (event: any) => {
    event.preventDefault()
    const { files } = event.target
    if (files.length) {
      const file = files[0]
      let nameArr = file.name.split('.')
      nameArr.pop()
      setFormValue({ ...formValue, name: nameArr.join("") })
      setFileSelected(file)
      const fileUrl = await generateFileUrl(file)
      setFileLink(fileUrl)
    }

  }
  const handleReset = async (event: any) => {
    event?.preventDefault();
    // const inputFile = document.querySelector(".form_video-upload input")

    setFileSelected(null)
    setFileLink(null)
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    console.log({event});
    
    if(event.target.type !== 'submit'){
      return
    }
    if (entityData) {
      // update
      console.log("update data");
      formValue.updated_at = new Date()
      if (fileSelected) {
        // change video
        const formData = new FormData()
        formData.append("video", JSON.stringify(formValue))
        formData.append("videoFile", fileSelected)

        const result: any = await updateDataWithFile("video", entityData._id, formData, setProgress)
        setUploadSuccess(result?.isSuccess)
        if (result?.isSuccess) {
          dispatch({
            type: ADD_NOTIFICATION,
            payload: {
              _id: generateId(),
              message: "Video updated !",
              status: "success",
              timeout: 2000
            }
          })
        }
        console.log({ result });
        setIsUploading(false)
      } else {
        // not change video 
        const data = { [routeData.entity === "Movie" ? "video" : routeData.entity.toLowerCase()]: formValue }
        console.log(data);
        const result: any = await updateData(routeData.entity, entityData._id, data)
        console.log(result);
        if (result?.isSuccess) {
          dispatch({
            type: ADD_NOTIFICATION,
            payload: {
              _id: generateId(),
              message: routeData.entity + " updated !",
              status: "success",
              timeout: 2000
            }
          })
        }


      }
    } else {
      // add
      console.log("add data");

      if (fileSelected) {
        setIsUploading(true)
        const formData = new FormData()
        formValue.created_at = new Date()
        formData.append("video", JSON.stringify(formValue))
        formData.append("videoFile", fileSelected)

        const result: any = await addDataWithFile("video", formData, setProgress)
        setUploadSuccess(result?.isSuccess)
        if (result?.isSuccess) {
          dispatch({
            type: ADD_NOTIFICATION,
            payload: {
              _id: generateId(),
              message: routeData.entity + " added !",
              status: "success",
              timeout: 2000
            }
          })
          setRedirect({
            isRedirect: true,
            path: "/dashboard/update/" + routeData.entity.toLowerCase() + "/" + result.result._id
          })
        }
        console.log({ result });
        setIsUploading(false)
      } else {
        const data = { [routeData.entity === "Movie" ? "video" : routeData.entity.toLowerCase()]: formValue }
        console.log(data);
        const result: any = await addData(routeData.entity, data)
        console.log(result);
        if (result?.isSuccess) {
          dispatch({
            type: ADD_NOTIFICATION,
            payload: {
              _id: generateId(),
              message: routeData.entity + " added !",
              status: "success",
              timeout: 2000
            }
          })
        }
      }
    }
  }

  const handleRegenerate = async () => {
    const data = await regenerateApplication(formValue._id)
    if (data.isSuccess) {
      setFormValue(data.result)
      dispatch({
        type: ADD_NOTIFICATION,
        payload: {
          _id: generateId(),
          message: "API KEY Regenereted !",
          status: "success",
          timeout: 2000
        }
      })
    }
  }


  const handleGoBack = () => {
    history.back(); // Cette fonction ramènera l'utilisateur à la page précédente dans l'historique de navigation.
  };

  if (uploadSuccess && redirect && redirect.isRedirect) {
    return <Navigate to={redirect.path} />
  }

  return (
    <div className="ManageEntityForm">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className={routeData.entity === "Movie" ? "col-lg-7" : "col-lg-12"}>
            <div className="row">
              {
                fields.map((field: string, index: number) => {
                  return <div className="col-12 form-group" key={index}>
                    {
                      ["description", "content"].includes(field) ?
                        <>
                          {/* <textarea
                          onChange={(e) => setFormValue({ 
                            ...formValue, 
                            [field]: e.target.value })}
                          id="text"
                          name={field}
                          rows={15}
                          className="form-control"
                          placeholder={capitalizeFirstLetter(field)}
                          defaultValue={formValue ? formValue[field] : ""}
                          value={formValue?.[field]}
                        /> */}
                          <TextEditor
                            value={formValue?.[field]}
                            handleChange={(value: string) => setFormValue({
                              ...formValue,
                              [field]: value
                            })}
                          />
                        </>

                        :

                        field === "template" ?
                          <SelectEmailTemplate
                            entityName='email_template'
                            handleChange={(value: string) => setFormValue({ ...formValue, [field]: value })}
                          /> :
                        field === "started_at" ?
                        <div>
                          <label htmlFor="">Start date : </label>
                          <input 
                            type="datetime-local" 
                            onChange={(e) => setFormValue({ ...formValue, [field]: e.target.value })}
                            className="form-control" 
                            value={viewDate(formValue?.[field])}
                          />
                        </div>
                          :
                          field === "video" ?
                            <SelectEmailTemplate
                              entityName='video'
                              handleChange={(value: string) => setFormValue({ ...formValue, [field]: value })}
                            />
                            :
                          field === "origins" ?
                            <AddOrigin
                              // name="Origin"
                              options={formValue?.[field] || []}
                              handleChange={(value: Origin[]) => setFormValue({ ...formValue, [field]: value })}
                              />
                              :
                              field === "options" ?
                              <AddOptions 
                              datas={formValue?.[field] || []}
                              handleChange={(value: any) => setFormValue({ ...formValue, [field]: value })}
                            />
                            :
                              field === "sections" ?
                              <SectionCRUD 
                              datas={formValue?.[field] || []}
                              handleChange={(value: any) => setFormValue({ ...formValue, [field]: value })}
                            />
                            :
                            <input
                              defaultValue={entityData ? entityData[field] : ""}
                              onChange={(e) => setFormValue({ ...formValue, [field]: e.target.value })}
                              type={field.toLowerCase() === "password" ? "password" : "text"}
                              name={field}
                              value={formValue?.[field]}
                              className="form-control"
                              placeholder={capitalizeFirstLetter(field)}
                            />

                    }

                  </div>
                })
              }

              {/* <div className="col-12 form-group">
                <input
                  defaultValue={formValue.name}
                  onChange={(e) => setFormValue({ ...formValue, name: e.target.value })}
                  type="text" name='name' className="form-control" placeholder="Title" />
              </div> */}
              {/* <div className="col-md-6 form-group">
                <select className="form-control" id="exampleFormControlSelect1">
                  <option disabled>Movie Category</option>
                  <option>Comedy</option>
                  <option>Crime</option>
                  <option>Drama</option>
                  <option>Horror</option>
                  <option>Romance</option>
                </select>
              </div>
              <div className="col-sm-6 form-group">
                <select className="form-control" id="exampleFormControlSelect2">
                  <option disabled>Choose quality</option>
                  <option>FULLHD</option>
                  <option>HD</option>
                </select>
              </div> */}


              {/* <div className="col-12 form-group">
                <select className="form-control" id="exampleFormControlSelect3">
                  <option selected disabled>Choose Language</option>
                  <option>English</option>
                  <option>Hindi</option>
                  <option>Tamil</option>
                  <option>Gujarati</option>
                </select>
              </div> */}

              {
                fileLink || routeData.action.toLowerCase() !== "movie" ?
                  <div className="col-12 form-group gap-1">
                    {
                      routeData.action !== "View" ?
                        <button
                          onClick={handleSubmit}
                          type="submit"
                          className="btn btn-primary mr-1">
                          Submit
                        </button>
                        :
                        null
                      // <Link to={"/update/" + routeData.entity + "/" + entityData?._id}
                      //   type="submit"
                      //   className="btn btn-primary mr-1">
                      //   Edit
                      // </Link>
                    }
                    <button type="reset" onClick={handleGoBack} className="btn btn-danger">cancel</button>
                  </div>
                  :
                  null
              }

            </div>
          </div>
          {
            routeData.entity === "Movie"
              ?
              <div className="col-lg-5">
                <div className="d-block position-relative">
                  {
                    !fileLink ?
                      <div className="form_video-upload">

                        <input
                          type="file"
                          accept="video/mp4,video/x-m4v,video/*"
                          onChange={handleSelectFile}
                        />

                        <p>Upload video</p>
                      </div>
                      :
                      null
                  }
                  {
                    fileLink ?
                      <div className="video-preview">
                        <OuitubePlayer src={fileLink} />
                        {/* <video width={"100%"} src={fileLink} controls={true}></video> */}
                        <h4>{fileSelected?.name || formValue.name}</h4>
                        <button className='btn btn-primary' onClick={handleReset}>Change</button>


                        {
                          isUploding ?
                            <ProgressBar progress={progress} />
                            :
                            null
                        }
                      </div>
                      :
                      null
                  }
                </div>
              </div>
              :
              null
          }
        </div>

      </form>
      {
        routeData.entity.toLowerCase() === "oauthclient" && routeData.action.toLowerCase() === "view" ?
          <>
            <KeyInput
              inputType="text"
              keyName="client Id"
              keyValue={formValue?.clientId}
            />
            <KeyInput
              inputType="password"
              keyName="Client Secret"
              keyValue={formValue?.clientSecret}
            />
            <KeyInput
              inputType="text"
              keyName="OAuth Redirect Authentication"
              keyValue={"https://api.ouitube.fr/oauth/authorize"}
            />
            <KeyInput
              inputType="text"
              keyName="Access token URL"
              keyValue={"https://api.ouitube.fr/oauth/access_token"}
            />
            <div className="col-12 p-0">
              <button onClick={handleRegenerate}
                className="btn btn-primary">
                Génerate Keys
              </button>
            </div>
          </>
          :
          null
      }
    </div>
  );
}

export default ManageEntityForm;